import { storageSelectedItemsKey, storageReportFormatKey } from '@/constants/report';

const getProjectReportItems = (projectId) => {
  const items = localStorage.getItem(`${storageSelectedItemsKey}-${projectId}`);
  return JSON.parse(items);
};

const setProjectReportItems = (projectId, fields) => localStorage.setItem(
  `${storageSelectedItemsKey}-${projectId}`,
  JSON.stringify(fields),
);

const getReportFormat = () => localStorage.getItem(storageReportFormatKey) || 'Microsoft Word (.docx)';
const setReportFormat = (format) => localStorage.setItem(storageReportFormatKey, format);

const utils = {
  getProjectReportItems,
  setProjectReportItems,
  getReportFormat,
  setReportFormat,
};

export default utils;
