import io from 'socket.io-client';
import jwtUtils from 'helpers/jwt-utils';

const getSocket = (query) => io(`wss://${window.location.host}:443`, {
  query,
  transportOptions: {
    polling: {
      extraHeaders: {
        Authorization: `Bearer ${jwtUtils.getFromStorage()}`,
      },
    },
  },
});

export default getSocket;
