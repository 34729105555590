<template>
  <!-- eslint-disable max-len, vue/html-indent, vue/html-closing-bracket-spacing -->
  <svg class="svg-component" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
    <path id="a" d="M15.474 19.21h-3.947a.79.79 0 1 1 0-1.58h3.947a.789.789 0 1 1 0 1.58zM5.252 16.835l-1.02-1.02a.788.788 0 1 1 1.116-1.117l1.02 1.021 1.022-1.021a.788.788 0 1 1 1.116 1.116l-1.021 1.021 1.02 1.021a.788.788 0 1 1-1.115 1.116l-1.021-1.02-1.021 1.02a.788.788 0 0 1-1.117 0 .788.788 0 0 1 0-1.116l1.021-1.02zm14.958-.784h-8.683a.79.79 0 1 1 0-1.58h8.683a.789.789 0 1 1 0 1.58zm-3.947-6.526h-4.736a.79.79 0 0 1 0-1.58h4.736a.79.79 0 1 1 0 1.58zm3.947-3.158h-8.683a.79.79 0 0 1 0-1.58h8.683a.79.79 0 1 1 0 1.58zM6.368 9.13a.788.788 0 0 1-.558-.232L4.231 7.32a.788.788 0 1 1 1.117-1.116l.946.947 2.214-2.847a.792.792 0 0 1 1.109-.139.792.792 0 0 1 .139 1.109L6.993 8.827a.795.795 0 0 1-.575.303l-.049.001z"/>
  </svg>
</template>

<script>
export default {
  name: 'ReviewersSvg',
};
</script>
