<template>
  <div id="app" :class="route.name">
    <div class="scrollbar-wrapper">
      <DiligenNav v-if="!route.meta.hideNav" :logged-in-user="loggedInUser" class="header" />
      <ErrorPage v-if="pageError" :error-title="pageError.errorTitle" :error-text="pageError.errorText" />
      <div v-else class="content-height">
        <!-- we keep SingleDocumentPage alive to mitigate pdfjs viewer memory leak problem with a singleton instance -->
        <keep-alive include="SingleDocumentPage">
          <router-view class="routed-content" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';

import ErrorPage from '@/views/error/ErrorPage.vue';
import DiligenNav from 'components/route-level/DiligenNav.vue';
import DiligenToast from 'plugins/diligen-toast';

Vue.use(DiligenToast);

export default {
  components: {
    DiligenNav,
    ErrorPage,
  },
  data() {
    return {
      offlineToast: null,
    };
  },
  computed: {
    ...mapState([
      'pageError',
      'route',
    ]),
    ...mapState('auth', [
      'loggedInUser',
    ]),
  },
  mounted() {
    const handleOnline = () => {
      this.offlineToast?.goAway(0);
      this.offlineToast = null;
    };
    const handleOffline = () => {
      this.offlineToast = this.$diligenToast.showWarning(
        'You are offline. You will not be able to save changes until your connection is restored.',
        { isSingleton: true, sticky: true },
      );
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    });
  },
};
</script>

<style lang="scss">
/* global diligen styles */
@import './styles/globals';
</style>

<style lang="scss" scoped>
// Note that ScrollOnClauseSelected directive points at this selector.
// After you make changes to it make sure jump to text functionality is in tact.
#app {
  overflow-x: hidden;
  height: 100%;
}

.scrollbar-wrapper {
  // Results in a negative right side margin when the page is scrollable equal to the scrollbar width.
  // This combined with overflow-x: hidden on #app prevents the page content from shifting when a scrollbar appears.
  margin-right: calc(100% - 100vw);
}

.header,
.routed-content {
  margin-left: $header-horizontal-margin;
  margin-right: $header-horizontal-margin;
}

.sdv {
  .header,
  .routed-content {
    margin-left: $main-sdv-margin;
    margin-right: $main-sdv-margin;
  }
}

.trainModel {
  .content-height {
    min-height: calc(100vh - #{$header-height}); // Calculated min-height for complete background-color coverage.
    background-color: $color-example-training-background;
  }

  .routed-content:not(.fader) {
    min-height: 100%;
  }
}

.api-documentation,
.changelog {
  .content-height {
    height: calc(100% - #{$header-height});
  }

  .scrollbar-wrapper {
    height: 100%; // Wrapper needs fixed height on api docs page for iframe to display correctly
  }
}

.changelog .content-height {
  overflow: auto;
}
</style>
