import { flatten, map, sortBy, throttle, uniq, uniqBy } from 'lodash';
import { THROTTLE_TIME } from '@/constants/pv';
import request from 'helpers/diligen-xhr';
import axios from 'helpers/diligen-xhr-axios';
import { isActive } from 'helpers/users';
import { isLeader, isTeamMember } from 'shared/collaborators-helper';

const mapReviewerToCollaborator = (reviewer) => ({
  id: reviewer.user_id,
  name: `${reviewer.first_name} ${reviewer.last_name}`,
});

export default {
  /**
   * @type {object} State
   * @property {Array} collaborators
   * @property {{ [key: number]: Array<object> }} documentReviewers
   */
  state: {
    collaborators: [],
    documentReviewers: {},
  },
  getters: {
    collaborators({ collaborators }) {
      return collaborators;
    },
    collaboratorsWithId({ collaborators }) {
      return collaborators.map(({ user_id, ...rest }) => ({ id: user_id, ...rest }));
    },
    collaborators_ids({ collaborators }) {
      return collaborators.map((x) => x.id);
    },
    project_leaders({ collaborators }) {
      return collaborators.filter(isLeader);
    },
    team_members({ collaborators }) {
      return collaborators.filter(isTeamMember);
    },
    reviewersOptGroups(state, { activeCollaborators, allCollaborators }) {
      return ({ onlyActive = false } = {}) => [{
        groupLabel: 'Reviewers',
        groupOptions: onlyActive ? sortBy(activeCollaborators, 'name') : allCollaborators,
      }];
    },
    /**
     * All collaborators - not only those that are active and set for the project
     * but also those that used to be set for the project and got assigned to some documents before being removed.
     * @returns {Array<object>}
     */
    allCollaborators(state, { activeCollaborators, exCollaborators }) {
      return sortBy(activeCollaborators.concat(exCollaborators), 'name');
    },
    /**
     * Ids of active collaborators and ex collaborators in the project.
     * @returns {number[]}
     */
    allCollaboratorIds(state, { allCollaborators }) {
      return map(allCollaborators, 'id');
    },
    /**
     * Collaborators which are active (not deactivated on /team page).
     * @returns {Array<object>}
     */
    activeCollaborators(state, { collaboratorsWithId }, rootState, rootGetters) {
      return collaboratorsWithId.filter((collaborator) => isActive(rootGetters['users/getUserById'](collaborator.id)));
    },
    /**
     * Collaborators removed from the project or deactivated after being assigned to one of the documents.
     * @returns {Array<object>}
     */
    exCollaborators(state, { reviewersRemovedFromProject }) {
      return reviewersRemovedFromProject.map(mapReviewerToCollaborator);
    },
    /**
     * Reviewers that have been removed from the project or deactivated after being assigned to one of the documents.
     * @returns {Array<object>}
     */
    reviewersRemovedFromProject(state, { activeCollaboratorsIds, initialReviewersIds, uniqReviewers }) {
      const idsOfRemovedReviewers = initialReviewersIds.filter((id) => !activeCollaboratorsIds.includes(id));
      return uniqReviewers.filter((reviewer) => idsOfRemovedReviewers.includes(reviewer.user_id));
    },
    /**
     * Ids of activeCollaborators in the project.
     * @returns {number[]}
     */
    activeCollaboratorsIds(state, { activeCollaborators }) {
      return map(activeCollaborators, 'id');
    },
    initialReviewersIds(state, { getIdsOfDocumentReviewers, reviewedDocumentIds }) {
      const ids = flatten(reviewedDocumentIds.map((documentId) => getIdsOfDocumentReviewers({ documentId })));
      return uniq(ids);
    },
    /**
     * Reviewers that reviewed at least one of the selected documents.
     * @returns {Array<object>}
     */
    uniqReviewers({ documentReviewers }) {
      const reviewers = flatten(Object.values(documentReviewers));
      return uniqBy(reviewers, 'user_id');
    },
    /**
     * Ids of documents that have at least one reviewer assigned.
     * @returns {number[]}
     */
    reviewedDocumentIds({ documentReviewers }) {
      return Object.keys(documentReviewers).map((documentId) => parseInt(documentId, 10));
    },
    getIdsOfDocumentReviewers({ documentReviewers }) {
      return ({ documentId }) => map(documentReviewers[documentId], 'user_id');
    },
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    SET_COLLABORATORS(state, collaborators) {
      state.collaborators = collaborators;
    },
    SET_DOCUMENT_REVIEWERS(state, documentReviewers) {
      state.documentReviewers = documentReviewers;
    },
  },
  /* eslint-enable no-param-reassign */
  actions: {
    async UPDATE_TEAM({ commit, rootGetters }, { projectId, collaborators }) {
      await request.post(`/api/projects/${projectId}/team`).send({ project_team: collaborators });

      const stateCollaborators = collaborators.map(({ user_id, project_role }) => {
        const { email, name, first_name, last_name } = rootGetters['users/getUserById'](user_id);
        return {
          user_id,
          email,
          project_role,
          name,
          first_name,
          last_name,
        };
      });
      commit('SET_COLLABORATORS', stateCollaborators);
    },
    FETCH_TEAM_DATA: throttle(async ({ commit, rootState }) => {
      const projectId = rootState.route.params.projectId;
      const response = await axios.get(`/api/projects/${projectId}/team`);
      commit('SET_COLLABORATORS', response.data.data.project_team);
    }, THROTTLE_TIME),
  },
};
