<template>
  <!-- eslint-disable max-len, vue/html-indent, vue/html-closing-bracket-spacing -->
  <svg ref="svgRoot" class="svg-component" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
    <path id="a" d="M11.292 11.292V6.628C11.285 6.272 11.637 6 12 6c.36 0 .708.268.708.621v4.67h4.671c.353 0 .621.35.621.709 0 .36-.268.708-.621.708h-4.67v4.671c0 .353-.35.621-.709.621-.36 0-.708-.268-.708-.621v-4.67H6.628C6.272 12.714 6 12.362 6 12c0-.36.268-.708.621-.708h4.67z"/>
  </svg>
</template>

<script>
export default {
  name: 'PlusSvg',
  methods: {
    updateViewBox(value) {
      this.$refs.svgRoot.setAttribute('viewBox', value);
    },
  },
};
</script>
