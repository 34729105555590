import request from 'helpers/diligen-xhr';
import { downloadResponse } from 'helpers/dom-utils';

/**
 * Get a function that can be called to trigger the report file download in the browser.
 *
 * @param {import('superagent').Response} response - The report response to download
 *
 * @return {() => Promise<void>} - The download function
 */
function getDownloadReportFunction({ body, headers }) {
  const contentDisposition = headers['content-disposition'];
  const fileNameStart = contentDisposition.indexOf('filename="') + 'filename="'.length;
  const fileName = decodeURIComponent(
    contentDisposition.substring(fileNameStart, contentDisposition.length - 1),
  );
  return () => downloadResponse(body, fileName, headers['content-type']);
}

export default {
  actions: {
    /**
     * Generate a report.
     *
     * @param {import('vuex').ActionContext} context - Vuex action context
     * @param {object} requestData - The report data
     *
     * @return {Promise<(() => Promise<void>)|null>} - The function to call to download the report
     */
    async generateReport(context, { project_id, ...requestData }) {
      const response = await request
        .post(`/api/projects/${project_id}/report`)
        .send(requestData)
        .responseType('blob');

      // Downloadable report
      if (response.statusCode === 200) {
        return getDownloadReportFunction(response);
      }
      // Mailed report
      return null;
    },
    /**
     * Get a report by uuid.
     *
     * @param {import('vuex').ActionContext} context - Vuex action context
     * @param {number} reportId - The id of the report to fetch
     *
     * @return {Promise<void>}
     */
    async getReport(context, reportId) {
      const response = await request.get(`/api/report/${reportId}`).responseType('blob');
      const download = getDownloadReportFunction(response);
      return download();
    },
  },
};
