<template>
  <div v-if="loggedInUser.isSubscribed && !loggedInUser.isSubscriptionActive">
    <router-link
      v-if="loggedInUser.permissions.includes('modify_subscription')"
      to="/subscription"
      title="There's a problem with your subscription.
Click here for more information."
    >
      <AlertSvg class="subscription-alert" />
    </router-link>
    <div
      v-else
      title="There's a problem with your subscription.
Please advise your System Owner to contact Diligen."
    >
      <AlertSvg class="subscription-alert" />
    </div>
  </div>
</template>

<script>
import AlertSvg from 'svg/Alert.vue';

export default {
  components: {
    AlertSvg,
  },
  props: {
    loggedInUser: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

.subscription-alert {
  height: 20px;
  width: 20px;
  color: $color-error;
  margin: 5px 12px 0 0;
}
</style>
