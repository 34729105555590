import * as Sentry from '@sentry/vue';

/**
 * Checks if error comes from Axios xhr call.
 * @param {object} error - thrown error to check.
 * @returns {boolean}
 */
const isAxiosError = error => error.name === 'AxiosError';

/**
 * Checks if error comes from Superagent xhr call.
 * @param {object} error - thrown error to check.
 * @returns {boolean}
 */
const isSuperAgentError = error => error.status && error.response && error.name !== 'AxiosError';

/**
 * Gets error message from API error response.
 * @param {object} error - thrown error, either from Axios or Superagent.
 * @param {string} genericError - a fallback error message to show in case API error is not in the expected format.
 * @returns {string}
 */
// eslint-disable-next-line max-len, complexity
const getErrorMessage = (error, genericError = 'An error occurred. Please try again, and if you continue to get this error, let us know at: <a href="mailto:help@diligen.com?subject=Question%20about%20Diligen" target="_blank">help@diligen.com</a>.') => {
  if (isAxiosError(error)) {
    // Handle only the structured errors expected from Axios
    if (error.response && error.response.data && error.response.data.errors) {
      return error.response.data.errors.map(err => err.title).join(', ');
    }
    if (error.response && error.response.data) {
      // If error.response.data exists but not error.response.data.errors, show the genericError
      // Handles server 401, 403, 502 and similar errors.
      return genericError;
    }
    // Log unexpected error structures to Sentry
    Sentry.captureException(error, {
      level: 'error',
      message: 'Unexpected error structure received from Axios',
      extra: {
        responseData: error.response ? error.response.data : 'No response data',
      },
    });
    throw error; // Rethrow if no suitable message was found
  }
  if (isSuperAgentError(error)) {
    // Log to Sentry if Superagent is still in use
    Sentry.captureMessage('Superagent is still being used in the application.');
    const errors = error.response?.body?.errors ?? [{ title: genericError }];
    return errors.map(x => x.title).join('<br>');
  }

  // If the error does not match Axios or Superagent, rethrow it
  throw error;
};

export default getErrorMessage;
