const HASH_SEPARATOR = '/';

/**
 * Converts mlKey and mlValue index to a hash url representation.
 * @param  {string} mlKey - ml key of selected clause.
 * @param  {number} mlValueIndex - index of the paragraph selected.
 * @return {string} - hash value.
 */
const toHashString = (mlKey, mlValueIndex) => `${mlKey}${HASH_SEPARATOR}${mlValueIndex}`;

export {
  HASH_SEPARATOR,
  toHashString,
};
