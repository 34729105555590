import { throttle } from 'lodash';
import qs from 'qs';
import Vue from 'vue';
import { downloadResponseFrom, forceBrowserDownload } from '../../../helpers/dom-utils';
import { withProcessingIndicator } from './actions-in-progress';
import { THROTTLE_TIME } from '@/constants/pv';
import { decoratedDocumentFactory } from '@/object-decorators/document';
import axios from 'helpers/diligen-xhr-axios';
import { filters, sort } from 'helpers/documents-query-params';
import { REVIEWED, PENDING } from 'shared/constants/review-status-codes';

const documentsMatchingSelection = ({ documents, selection }) => {
  if (selection.fileIds) {
    return documents.filter(doc => selection.fileIds.includes(doc.file_id));
  }
  return documents.filter(doc => !selection.unselectedFileIds.includes(doc.file_id));
};

// Add names to reviews using user_id.
const addUserDataToReviews = (reviews, rootGetters) => reviews.map(review => {
  const { first_name, last_name } = rootGetters['users/getUserById'](review.user_id);
  return { ...review, first_name, last_name };
});

export default {
  state: {
    selectUsingFilters: false,
    cherryPickedDocs: [],
    document_project_fields: [],
    documents: [],
    documentsCount: 0,
    reviews: [],
    editColumnsShown: false,
    moreFiltersExpanded: false,
  },
  getters: {
    /**
     * Gets information about sorted column and direction from the query or the default value
     * @return {string} sorted info like -assignment
     */
    sort: (state, getters, rootState) => sort(rootState.route.query),

    /**
     * Gets name of the sorted column
     * @return {string} name of the sorted column
     */
    sortColumn: (state, getters) => getters.sort.substring(getters.sort.startsWith('-') ? 1 : 0),

    /**
     * Checks if all documents in the project are selected
     * @return {boolean}
     */
    allDocumentsSelected: ({ documentsCount }, { selectedDocumentsCount }) => selectedDocumentsCount === documentsCount,

    /**
     * Gets number of selected documents on all pages
     * @return {number}
     */
    selectedDocumentsCount({ documentsCount, selectUsingFilters }, { unselectedFileIds, selectedFileIds }) {
      return selectUsingFilters
        ? documentsCount - unselectedFileIds.length
        : selectedFileIds.length;
    },
    /**
     * Gets reviews of all selected documents
     * @return {Array<object>}
     */
    reviewsOfSelectedDocuments({ selectUsingFilters, reviews }, { selectedFileIds, unselectedFileIds }) {
      return selectUsingFilters
        ? reviews.filter(review => !unselectedFileIds.includes(review.file_id))
        : reviews.filter(review => selectedFileIds.includes(review.file_id));
    },

    /**
     * Gets a function that tells you if a given document is selected.
     * @return {Function} - function that takes file id as parameter and returns Boolean.
     */
    isDocumentSelected({ selectUsingFilters }, { selectedFileIds, unselectedFileIds }) {
      return id =>
        (selectUsingFilters ? !unselectedFileIds.includes(id) : selectedFileIds.includes(id));
    },

    /**
     * Maps provided fileTags (files_tags table) to Tags (tags table).
     * @param {object} state - vuex state to read from.
     * @param {Array<object>} state.tags - tags from vuex state.
     * @return {Function} - function that takes fileTags as parameter and returns Tags they map to.
     */
    getTagsFromFileTags({ tags }) {
      return fileTags => fileTags.map(fileTag => tags.find(tag => tag.id === fileTag.tag_id));
    },

    /**
     * Gets a function that returns reviews from a specified contract
     * @return {Function} - function that takes document id as parameter
     */
    documentReviews({ reviews }) {
      return id => reviews.filter(review => review.file_id === id);
    },

    /**
     * Gets cherry picked documents that have been selected.
     * @param {object} state - vuex state to read from.
     * @param {Array<object>} state.cherryPickedDocs - Array of picked files.
     * @return {Array<object>} explicitly selected documents.
     */
    selectedDocuments({ cherryPickedDocs }) {
      return cherryPickedDocs.filter(doc => doc.selected === true);
    },

    /**
     * Gets ids of selected files.
     * @param {object} state - vuex state to read from.
     * @param {object} getters - vuex getters to read from.
     * @param {Array<object>} getters.selectedDocuments - Array of picked files.
     * @return {Array} ids of explicitly selected files.
     */
    selectedFileIds(state, { selectedDocuments }) {
      return selectedDocuments.map(doc => doc.file_id);
    },

    /**
     * Gets ids related to unselected files.
     * @param {{ cherryPickedDocs: Array<object> }} cherryPickedDocs - Array of picked files.
     * @return {Array} ids of explicitly unselected files.
     */
    unselectedFileIds({ cherryPickedDocs }) {
      return cherryPickedDocs.filter(doc => doc.selected === false).map(doc => doc.file_id);
    },

    /**
     * Gets documents decorated with some useful functions/getters
     * @return {Array<object>}
     */
    decorated_documents_array({ documents }, getters, rootState) {
      return documents.map(document => decoratedDocumentFactory(document, rootState.auth.loggedInUser.dateFormat));
    },

    /**
     * Plucks file ids from documents that are in state.
     * @param  {object} state - vuex state you read from.
     * @param  {object} state.documents - current documents.
     * @return {Array<number>} - array of file IDs.
     */
    pageFileIds({ documents }) {
      return documents.map(document => document.file_id);
    },

    /**
     * Gets a curried function that is able to build SDV Location based on current state and passed in arguments.
     * This built Location can be passed to router-link as a :to prop.
     * @param  {object} state - vuex state you read from.
     * @param  {number} state.documentsCount - total number of documents in the project.
     * @param  {object} getters - vuex getters you read from.
     * @param  {number} getters.project_id: projectId - id of the current project.
     * @param  {Array<number>} getters.pageFileIds - file ids from current documents.
     * @param  {object} rootState - root vuex state
     * @return {Function} - curried function building SDV Location.
     */
    sdvLocationBuilder({ documentsCount }, { project_id: projectId, pageFileIds }, rootState) {
      /**
       * Build SDV Location in the context of this vuex state.
       * @param  {object} decoratedDocument - decorated document (see object-decorators/document.js).
       * @param  {string} [mlKey] - optional mlKey that tells on which clause we want to focus when we reach SDV.
       * @return {Location} - object that you can pass to router-link as the :to prop.
       */
      return (decoratedDocument, mlKey) => decoratedDocument.sdvLocationBuilder({
        projectId,
        mlKey,
        query: {
          ...rootState.route.query,
          pageFileIds,
          documentsCount,
        },
      });
    },

    /**
     * Checks if the sort is in ascending order
     * @return {boolean}
     */
    isSortedAsc: (state, getters) => !getters.sort.startsWith('-'),

    /**
     * Gets a function that checks if the given column is the one used for sorting
     * @return {Function} function that takes column name as a parameter
     */
    isSortedBy: (state, { sortColumn }) =>
      column => column === sortColumn,

    /**
     * Checks if any documents are selected
     * @return {boolean}
     */
    hasSelectedDocuments({ selectUsingFilters }, { selectedFileIds }) {
      return selectUsingFilters || selectedFileIds.length > 0;
    },

    /**
     * Gets all documents from the current page that are selected
     * @return {Array<object>}
     */
    currentPageSelectedDocuments({ documents }, { isDocumentSelected }) {
      return documents.filter(doc => isDocumentSelected(doc.file_id));
    },
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    ADD_DOCUMENTS_PROJECT_FIELDS(state, projectFields) {
      state.document_project_fields = projectFields;
    },
    REMOVE_DOCUMENTS_PROJECT_FIELD(state, id) {
      state.document_project_fields = state.document_project_fields.filter(projectField => projectField.id !== id);
    },
    UPDATE_DOCUMENT_PROJECT_FIELD(state, { project_field_id: id, file_id, values, overwrite = true }) {
      const projectField = state.document_project_fields.find(field => field.id === id);
      const fileField = projectField?.data.find(fileData => fileData.file_id === file_id);
      if (fileField) {
        fileField.data.values = overwrite ? values : [...fileField.data.values, ...values];
      }
    },
    /**
     * Adds empty data for added project field.
     * Helps to keep document_project_fields in sync with PV project_fields.
     * @param {object} state - vuex state to mutate.
     * @param {object} field  - project field just added, no documents yet have data for this field.
     */
    addEmptyProjectFieldData(state, field) {
      state.document_project_fields.push({
        ...field,
        data: [],
      });
    },
    ADD_DOCUMENTS(state, { mappedDocuments: documents, documentsCount }) {
      state.documents = documents;
      state.documentsCount = documentsCount;
    },
    REMOVE_DOCUMENTS(state, { file_ids, unselected_file_ids }) {
      if (file_ids) {
        state.documentsCount -= file_ids.length;
        state.documents = state.documents.filter(doc => !file_ids.includes(doc.file_id));
      }
      if (unselected_file_ids) {
        const remainingDocuments = state.documents.filter(doc => unselected_file_ids.includes(doc.file_id));
        state.documentsCount = remainingDocuments.length;
        state.documents = remainingDocuments;
      }
    },
    UPDATE_DOCUMENT(state, { fileId, mergedAttributes }) {
      const document = state.documents.find(doc => doc.file_id === fileId);
      if (document) {
        document.ml_json = { ...document.ml_json, ...mergedAttributes.ml_json };
      }
    },
    setReviews(state, reviews) {
      state.reviews = reviews;
    },
    changeSelectionForDocuments(state, { documents, selected }) {
      documents.forEach(changedDoc => {
        const alreadyPicked = state.cherryPickedDocs.find(doc => doc.file_id === changedDoc.file_id);
        if (alreadyPicked) {
          alreadyPicked.selected = selected;
        }
        else {
          state.cherryPickedDocs = [...state.cherryPickedDocs, { ...changedDoc, selected }];
        }
      });
    },
    changeSelectionForAll(state, selected) {
      state.cherryPickedDocs = [];
      state.selectUsingFilters = selected;
    },
    /**
     * Changes status (file_status attribute) for specified documents.
     * @param {object} state - vuex state to mutate.
     * @param {object} options
     * @param {object} options.selection - object defining selection of files.
     * @param {string} options.status - new status to apply - see file-processing-statuses for available options.
     * @param {Array<{ id: number, file_status: string }>} options.unprocessable - The unprocessable files.
     */
    changeStatus(state, { selection, status, unprocessable = [] }) {
      const fileIds = documentsMatchingSelection({
        documents: state.documents,
        selection,
      }).map(doc => doc.file_id);

      state.documents = state.documents.map(document => {
        if (fileIds.includes(document.file_id)) {
          const unprocessedReason = unprocessable.find(data => data.id === document.file_id)?.file_status;
          return { ...document, file_status: unprocessedReason || status };
        }
        return document;
      });
    },
    socketChangeStatus(state, reprocessedFiles) {
      state.documents = state.documents.map(document => {
        const reprocessed = reprocessedFiles.find(({ file_id }) => document.file_id === file_id);
        return reprocessed
          ? { ...document, file_status: reprocessed.file_status }
          : document;
      });
    },
    /**
     * Removes fileTag from file which represents removing relationship between a file and tag.
     * @param {object} state - vuex state to mutate.
     * @param {object} options
     * @param {number} options.tagId - the tag ID for which relationship is removed.
     * @param {number} options.fileId - the file ID for which relationship is removed.
     */
    removeFileTag(state, { tagId, fileId }) {
      const file = state.documents.find(doc => doc.file_id === fileId);
      file.fileTags = file.fileTags.filter(({ tag_id }) => tag_id !== tagId);
    },
    addFileTag(state, { tagId, fileId }) {
      const file = state.documents.find(doc => doc.file_id === fileId);
      file.fileTags.push({ file_id: fileId, tag_id: tagId });
    },
    resetSelection(state) {
      state.cherryPickedDocs = [];
      state.selectUsingFilters = false;
    },
    toggleEditColumns(state) {
      state.editColumnsShown = !state.editColumnsShown;
    },
    toggleMoreFilters(state) {
      state.moreFiltersExpanded = !state.moreFiltersExpanded;
    },
    MARK_FILES_DELETED(state, deletedFileIds) {
      const deletedFiles = state.documents.filter(doc => deletedFileIds.includes(doc.file_id));
      deletedFiles.forEach(file => {
        file.isDeleted = true;
      });
    },
    MARK_FILES_IN_SCOPE(state, { fileIds }) {
      state.documents.forEach(file => {
        if (fileIds.includes(file.file_id)) {
          file.out_of_scope = null;
        }
      });
    },
    MARK_FILES_OUT_OF_SCOPE(state, { fileIds, userId }) {
      state.documents.forEach(file => {
        if (fileIds.includes(file.file_id)) {
          file.out_of_scope = userId;
        }
      });
    },
    MARK_FILES_REVIEWED(state, { fileIds, userId }) {
      state.reviews.forEach(review => {
        if (review.user_id === userId && fileIds.includes(review.file_id) && review.review_status !== REVIEWED) {
          review.review_status = REVIEWED;
        }
      });
    },
    MARK_FILES_UNREVIEWED(state, { fileIds, userId }) {
      state.reviews.forEach(review => {
        if (review.user_id === userId && fileIds.includes(review.file_id) && review.review_status !== PENDING) {
          review.review_status = PENDING;
        }
      });
    },
    UPDATE_REVIEWERS(state, { addedReviewers, removedReviewers }) {
      state.reviews = state.reviews
        .concat(addedReviewers)
        .filter(({ user_id, file_id }) =>
          !removedReviewers.some(removed => user_id === removed.user_id && file_id === removed.file_id));
    },
  },
  /* eslint-enable no-param-reassign */
  actions: {
    DELETE_DOCUMENTS: withProcessingIndicator(
      'deleting',
      async ({ commit, dispatch, getters }, filesActionSelection) => {
        await axios.delete('/api/documents', {
          data: filesActionSelection,
        });
        await dispatch('FETCH_PROJECT_FILE_INFO', { projectId: getters.project_id });
        commit('REMOVE_DOCUMENTS', filesActionSelection);
      },
    ),
    FETCH_DOCUMENTS: throttle(withProcessingIndicator('fetching', async (
      { commit, getters, rootGetters, rootState },
      { projectId: newId = undefined, ...query } = {},
    ) => {
      try {
        const currentQuery = rootState.route.query;
        const projectId = newId || getters.project_id;
        const withDefaults = rootGetters['project/withDefaults'];

        const url = `/api/projects/${projectId}/documents`;
        const params = withDefaults({ ...currentQuery, ...query });

        const response = await axios.get(url, {
          params,
          timeout: 60000,
        });
        const { project_fields = [], documents = [], reviews = [] } = response.data.data || {};
        const { row_count: documentsCount = 0 } = response.data.meta || {};

        const mappedDocuments = documents.map(({ file_tags, ...document }) => ({
          fileTags: file_tags,
          isDeleted: false,
          ...document,
        }));

        commit('ADD_DOCUMENTS', { mappedDocuments, documentsCount });
        commit('setReviews', addUserDataToReviews(reviews, rootGetters));
        commit('ADD_DOCUMENTS_PROJECT_FIELDS', project_fields);
      }
      catch (error) {
        if (error.code === 'ECONNABORTED' || error.message.includes('timeout')) {
          // Axios throws error with code 'ECONNABORTED' on timeout
          Vue.diligenToast.showError(
            'The search request timed out. There are too many documents to perform this search.',
          );
        }
        throw error;
      }
    }), THROTTLE_TIME),
    BULK_DOWNLOAD: (context, { projectId, ...query }) => {
      forceBrowserDownload(`/api/projects/${projectId}/documents/download?${qs.stringify(query)}`);
    },
    PAGE_DOWNLOAD: (context, { projectId, ...query }) => {
      const axiosPromise = axios.post(`/api/projects/${projectId}/documents/download`, null, {
        params: query,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' }),
        responseType: 'blob',
      });
      downloadResponseFrom(axiosPromise);
    },
    /**
     * Get the reviews for files, optionally filtered.
     * @param {object} context - vuex action context.
     * @param {object} context.getters - vuex getters to read from.
     * @param {object} context.rootGetters - vuex rootGetters to read from.
     * @param {object} context.rootState - vuex rootState to read from.
     * @returns {Promise<Array<object>>}
     */
    async fetchReviews({ getters: { project_id: projectId, unselectedFileIds }, rootGetters, rootState: { route } }) {
      const id = projectId || route.params.projectId;
      const { data } = await axios.get(`/api/projects/${id}/reviews`, {
        params: {
          filter: filters(route.query),
          unselected_file_ids: unselectedFileIds,
        },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
      });
      const { reviews } = data.data;
      const mappedReviews = Object.keys(reviews).map(key => [key, addUserDataToReviews(reviews[key], rootGetters)]);
      return Object.fromEntries(mappedReviews);
    },

    /**
     * Gets fileTags (files_tags table) of filtered files.
     * @param {object} context - vuex action context.
     * @param {object} context.getters - vuex getters to read from.
     * @param {object} context.rootState - vuex rootState to read from.
     * @returns {Promise<Array<object>>}
     */
    async fetchFileTagsFromFilteredFiles({ getters, rootState: { route } }) {
      const { project_id: projectId, unselectedFileIds } = getters;
      const response = await axios.get(`/api/projects/${projectId}/fileTags`, {
        params: {
          filter: filters(route.query),
          unselectedFileIds,
        },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
      });
      return response.data.data.file_tags;
    },

    /**
     * Removes fileTag from file which represents removing relationship between a file and tag.
     * Tells server to do so with xhr request and removes the relationship from the vuex state.
     * @param {object} context
     * @param {Function} context.commit - vuex commit function that calls a mutation.
     * @param {object} context.rootState - vuex root state.
     * @param {object} options
     * @param {number} options.tagId - the tag ID for which relationship is removed.
     * @param {number} options.fileId - the file ID for which relationship is removed.
     * @returns {Promise}
     */
    async removeFileTag({ commit, rootState }, { tagId, fileId }) {
      await axios.post('/api/files_tags', {
        unapplied: [tagId],
        applied: [],
        fileIds: [fileId],
      }, {
        headers: {
          clientID: rootState.project.socket.id,
        },
      });
      commit('removeFileTag', { tagId, fileId });
    },
    /**
     * Reprocess documents and handle unprocessable files.
     * Status updates will come through socket updates.
     */
    reprocessDocuments: withProcessingIndicator('reprocessing', async (context, filesActionSelection) => {
      const { commit, dispatch, getters } = context;

      // Make the reprocess API call
      const { data } = await axios.post(
        `/api/projects/${filesActionSelection.projectId}/reprocess`,
        filesActionSelection,
      );

      // Refresh project file info to get latest state
      await dispatch('FETCH_PROJECT_FILE_INFO', { projectId: getters.project_id });

      // Reset selection state
      commit('resetSelection');

      return data;
    }),
    /**
     * Expand the provided section in PV. Ensures only one expanded section is open at a time.
     *
     * @param {object} context - Vuex context.
     * @param {Function} context.commit - Vuex commit function that calls a mutation.
     * @param {object} context.state - Vuex state to read from.
     * @param {'columns' | 'filters'} section - The section to toggle.
     */
    toggleExpandedSection({ commit, state }, section) {
      if (section === 'columns') {
        if (state.moreFiltersExpanded) {
          commit('toggleMoreFilters');
        }
        commit('toggleEditColumns');
      }

      if (section === 'filters') {
        if (state.editColumnsShown) {
          commit('toggleEditColumns');
        }
        commit('toggleMoreFilters');
      }
    },
  },
};
