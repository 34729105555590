import { isEmpty } from 'lodash';
import Vue from 'vue';
import { OCR, ORIGINAL, SEARCH_ENABLED } from '@/constants/sdv';
import { isSpecialClause } from '@/object-decorators/document-field';
import { canLoadPdf } from 'helpers/browser-info';
import { hexToRgb } from 'helpers/colors';
import request from 'helpers/diligen-xhr';
import axios from 'helpers/diligen-xhr-axios';
import { blobToBase64 } from 'helpers/dom-utils';
import getErrorMessage from 'helpers/get-api-error-message';
import { getClause, getProjectField, getField } from 'helpers/project-field-and-clause';
import { HASH_SEPARATOR, toHashString } from 'helpers/sdv-url-hash';
import getSocket from 'helpers/socket-utils';
import { nameCompare } from 'helpers/sort-utils';
import { ABORTED } from 'shared/constants/error-codes';
import { ERROR_IN_OCR } from 'shared/constants/file-processing-statuses';
import { OUT_OF_SCOPE } from 'shared/constants/review-status-codes';
import { isProcessed } from 'shared/processed-file';
import {
  getDocumentType,
  getParagraphsWithAdditional,
} from 'shared/shared-clause-helper';
import permissionsModule from 'store/modules/project/permissions';
import sdvSocketHandler from 'store/sdvSocketHandler';

const canBrowserLoadPdf = canLoadPdf();

// Bunch of pure functions that can be used as getters but can also
// be reused in mutations where getters are not available but state is
// helps the code stay DRY

/**
 * Gets rawDocument
 * @param {object} rawDocumentData - rawDocumentData from state
 * @return {object} raw document
 */
const getRawDocumentFromState = ({ rawDocumentData }) =>
  rawDocumentData?.file_details ?? {};

/**
 * Gets file name
 * @param {object} state - vuex state to read
 * @return {string} file name of the contract
 */
const getFileNameFromState = state =>
  getRawDocumentFromState(state)?.file_name ?? '';

/* eslint-disable no-param-reassign */

/**
 * Update clause mutation. Extracted to be reused when saving clauses in document overview.
 * @param {object} state - vuex state to mutate
 * @param {object} mlObject
 * @param {string} mlObject.key - ml key for the clause that we want to update.
 * @param {object} mlObject.mlValue - new clause mlValue.
 */
const UPDATE_CLAUSE = (state, { key, mlValue }) => {
  state.mappedData[key] = mlValue;
};

const ocrErrorMessage = 'Diligen could not analyze this document. The document may be an invalid PDF file or have access restrictions. You can still manually complete all fields for this document.'; // eslint-disable-line max-len

/* eslint-enable no-param-reassign */

export default {
  state: {
    mlJsonDataExplicitUpdate: 0,
    collapsedParagraphs: [],
    currentPage: undefined,
    clauses: [],
    diligenPdfViewer: { eventBus: null },
    projectFields: [],
    projectTags: [],
    fileTags: [],
    documentReviews: [],
    examples: [],
    fieldsBeingEdited: [],
    fieldsBeingEditedRemote: [],
    isDeleted: false,
    isFindBarOpened: false,
    isParagraphQuery: false,
    isOcrPdf: false,
    mainLoadingMessage: null,
    mainLoadingOpacity: 0,
    reprocessCallsInProgress: 0,
    isPDFVisible: canBrowserLoadPdf,
    fileData: null,
    pdfEventBus: new Vue(),
    pdfFindBar: {},
    selectClauseBus: new Vue(),
    rawDocumentData: {},
    mappedData: {},
    sdvInitiated: false,
    selectedClauseOrigin: 'url',
    socket: null,
    userReviewStatus: null,
    recentlyTrainedClauseIds: [],
    totalPages: 0,
  },
  getters: {
    pdfViewerEventBus(state) {
      return state.diligenPdfViewer.eventBus;
    },
    /**
     * Check if we called reprocess and are waiting for the server to respond with the document status.
     * @param {object} state - vuex state to read.
     * @returns {boolean}
     */
    isReprocessInProgress(state) {
      return state.reprocessCallsInProgress > 0;
    },
    /**
     * Checks if this document is a pdf converted from a docx document.
     * @param {object} state - vuex state to read
     * @param {object} getters - vuex getters to read
     * @param  {object} getters.rawDocument - rawDocument from getters.
     * @return {boolean}
     */
    converted(state, { rawDocument }) {
      return rawDocument.is_converted;
    },
    /**
     * Check if there's an OCR error and return the associated error message.
     * @param {object} state - The modulo read
     * @param {object} getters - vuex getters to re getters.
     * @param {string} getters.processedStatus - The processed status of the current document.
     * @return {false | string}
     */
    errorInOcrMessage(state, { processedStatus }) {
      return (processedStatus === ERROR_IN_OCR) && ocrErrorMessage;
    },
    /**
     * Checks if file name extension indicates that it is an image.
     * @param {object} state - vuex state to read.
     * @param {object} getters - vuex getters to read.
     * @param  {string} getters.fileName - name of the file.
     * @returns {boolean} - true if url indicates it is an image.
     */
    hasImageExtension(state, { fileName }) {
      const imageExtensions = [
        'apng',
        'bmp',
        'cur',
        'gif',
        'ico',
        'jpg',
        'jpeg',
        'jpe',
        'png',
        'tiff',
        'tif',
        'webp',
        'svg',
      ];
      return fileName && imageExtensions.some(ext => fileName.toLowerCase().endsWith(`.${ext}`));
    },
    /**
     * Checks if file is an image that has not been converted to pdf.
     * @param {object} state - vuex state to read.
     * @param {object} getters - vuex getters to read.
     * @param {boolean} getters.converted - has the file been converted to pdf ?
     * @param {boolean} getters.hasImageExtension - does the file name have image extension ?
     * @returns {boolean} - true if it's an image that we did not convert.
     */
    isNotConvertedImage(state, { converted, hasImageExtension }) {
      return hasImageExtension && !converted;
    },
    /**
     * Checks the search feature availability based on current state of the sdv.
     * @param {object} state - vuex state to read.
     * @param {object} getters - vuex getters to read.
     * @returns {string} - text describing the search feature status.
     */
    pdfSearchStatus(state, getters) {
      if (getters.isNotConvertedImage) {
        return 'Search feature has been disabled as the uploaded file seems to be an image.';
      }
      if (!state.isPDFVisible) {
        const searchView = getters.isOCRAvailable ? OCR : ORIGINAL;
        return `Search is available only in ${searchView} view`;
      }
      // We are on Original view and OCR view is available.
      if (!state.isOcrPdf && getters.isOCRAvailable) {
        return `Search is available only in ${OCR} view`;
      }
      // If we got here we are either on Original with no OCR or on OCR.
      return SEARCH_ENABLED;
    },
    /**
     * Checks if the search feature is enabled based on current state of the sdv.
     * @param {object} state - vuex state to read.
     * @param {object} getters - vuex getters to read.
     * @param {string} getters.pdfSearchStatus - the search feature status.
     * @returns {boolean} true if search is enabled.
     */
    isSearchEnabled(state, { pdfSearchStatus }) {
      return pdfSearchStatus === SEARCH_ENABLED;
    },
    /**
     * Gets selected clause (the one we want to highlight in extracted clauses and pdf/plain-text sections)
     * @param {object} state - vuex state to read
     * @param {object} getters - vuex getters to read
     * @param {object} rootState - vuex rootState with vuex-router hash from which we get selected clause information
     * @return {object} - object representing selected clause with mlKey and mlValueIndex (which paragraph is selected)
     */
    selectedClause(state, getters, rootState) {
      if (!rootState.route.hash) {
        return {
          mlKey: null,
          mlValueIndex: null,
        };
      }
      const [mlKey, mlValueIndex] = rootState.route.hash.substring(1).split(HASH_SEPARATOR);
      return {
        mlKey,
        mlValueIndex: parseInt(mlValueIndex, 10),
      };
    },

    /**
     * Gets rgb color of the clause that we want to select (jump to). Can be used as bg styling.
     * @param {object} state - vuex state to read.
     * @param  {object} getters - vuex getters to read from.
     * @return {string} rgb color of selected clause.
     */
    selectedClauseRgbColor(state, getters) {
      const clause = getters.getClause(getters.selectedClause.mlKey);
      if (!clause) {
        return 'inherit';
      }
      const { r, g, b } = hexToRgb(clause.hex_color);
      return `rgba(${r},${g},${b},0.1)`;
    },
    /**
     * Default sorted order of fields which are all regular clauses and project fields that have value
     * @param {object} state - state from vuex
     * @param {Array<object>} state.projectFields - Project fields from state
     * @param {object} getters
     * @param {Array<object>} getters.regularClauses - No secondary coc and no "special clauses" like "Contract Date"
     * @param {Function} getters.getMlValue - function that gets mlValue using mlKey
     *
     * @return {Array<object>} fields with values ordered by name
     */
    defaultFieldsOrder({ projectFields }, { regularClauses, getMlValue }) {
      const extractedClauses = regularClauses.filter(({ ml_key: mlKey }) => !isEmpty(getMlValue(mlKey)));
      const projectFieldsWithValues = projectFields.filter(field => !isEmpty(field?.values));
      return extractedClauses.concat(projectFieldsWithValues).sort(nameCompare);
    },
    /**
     * Gets clause from state
     * @param {{ clauses: Array<object> }} state - vuex state to read with clauses as it's property
     * @return {Function} function that accepts mlKey as argument and returns clause {object}
     */
    getClause({ clauses }) {
      return mlKey => getClause({ clauses, mlKey });
    },
    /**
     * Gets project field from state
     * @param {object} state - vuex state to read with projectFields as it's property
     * @return {Function} function that accepts project column id as argument and returns projectField {object}
     */
    getProjectField({ projectFields }) {
      return id => getProjectField({ projectFields, id });
    },
    /**
     * Provides a function that gets mlValue using mlKey.
     * @param  {object} state - this module state.
     * @param  {number} state.mlJsonDataExplicitUpdate - notifier about (unobserved) mlJsonData change.
     * @param  {object} state.mappedData - the mapped document data.
     * @param  {object} getters - this module getters.
     *
     * @return {Function} function that accepts mlKey as a parameter and returns mlValue (using current vuex state)
     */
    getMlValue({ mappedData }) {
      return mlKey => mappedData[mlKey] || [];
    },

    contractName({ mappedData }) {
      return getParagraphsWithAdditional({ mlJsonData: mappedData, mlKey: 'contract_name' })[0];
    },

    documentType: ({ mappedData }) => getDocumentType({ mlJsonData: mappedData }),

    /**
     * Get file id from url
     * @param  {object} state - vuex state to read from
     * @param  {object} getters - vuex getters to read from
     * @param  {object} route - vuex router object providing url values
     * @return {number} - id of current file
     */
    fileId(state, getters, { route }) {
      return parseInt(route.params.fileId, 10);
    },
    fileName: getFileNameFromState,
    /**
     * Gets a function that returns visible ordered fields based on the preferences passed in
     * This is used both in visibleFields getter and in allFieldsEditor.vue
     * where we use local (unsaved) preferences to compute visible fields.
     * If user made a choice which fields to show we use user's preference.
     * Otherwise the default is to show those fields that have extracted data concatenated with project fields
     * @param {object} state - The module state.
     * @param {Array<object>} state.projectFields - project fields from state
     * @param {Array<object>} state.clauses - The clauses from state.
     * @param {object} getters - The module getters.
     * @param {Array<object>} getters.defaultFieldsOrder - Default order of fields
     * @return {Function} function that takes preferences as an argument and returns visible fields
     */
    getVisibleFields({ projectFields, clauses }, { defaultFieldsOrder }) {
      return preferences => (preferences
        ? preferences.map(preference => getField(preference, clauses, projectFields))
        : defaultFieldsOrder);
    },
    isOCRAvailable(state, { rawDocument }) {
      return rawDocument.is_ocr;
    },
    isOriginalPdf({ isOcrPdf }) {
      return !isOcrPdf;
    },
    /**
     * Checks if the selected paragraph was manually inserted
     * @param {object} state - Vuex state (not used in this getter)
     * @param {object} obj - Object containing the selectedParagraph
     * @param {object} obj.selectedParagraph - The currently selected paragraph
     * @return {boolean} True if the paragraph was manually inserted, false otherwise
     */
    isSelectedParagraphManuallyInserted(state, { selectedParagraph }) {
      const locations = selectedParagraph?.locations ?? [];
      return locations.length === 0;
    },

    /**
     * Gets processed status
     * @param {object} state - vuex state to read
     * @param  {object} rawDocument - rawDocument from getters
     * @return {string}
     */
    // eslint-disable-next-line no-unused-vars
    processedStatus({ mlJsonDataExplicitUpdate }, { rawDocument }) {
      return rawDocument.file_status;
    },
    /**
     * Checks if document is in processing pipeline.
     * @param {object} state - vuex state to read.
     * @param {object} getters - vuex getters to read.
     * @param {number} getters.processedStatus - document status to check.
     * @return {boolean} true if document is being processed.
     */
    isBeingProcessed(state, { processedStatus }) {
      return processedStatus !== undefined && !isProcessed({ file_status: processedStatus });
    },
    /**
     * Get project id from url
     * @param  {object} state - vuex state to read from
     * @param  {object} getters - vuex getters to read from
     * @param  {object} route - vuex router object providing url values
     * @return {number} - id of the current project
     */
    projectId(state, getters, { route }) {
      return parseInt(route.params.projectId, 10);
    },
    rawDocument: getRawDocumentFromState,
    /**
     * Clauses without secondary coc models and without "special clauses" like "Contract Date" or "Contract Name"
     * @param  {object} state - vuex state to read from
     * @param  {Array<object>} state.clauses - The clauses to filter.
     * @return {Array<object>} clause objects
     */
    regularClauses: ({ clauses }) => clauses.filter(clause => !isSpecialClause(clause)),

    recentClauses: ({ clauses, recentlyTrainedClauseIds }) => recentlyTrainedClauseIds
      .map(clauseId => clauses.find(clause => clause.id === clauseId)),

    /**
     * Clauses that show up in Document Overview and cannot be hidden by user.
     * @param  {object} state - vuex state to read from
     * @param  {Array<object>} state.clauses - The clauses to filter.
     * @return {Array<object>} clause objects
     */
    specialClauses: ({ clauses }) => clauses.filter(isSpecialClause),

    /**
     * Gets the selected paragraph based on the selected clause
     * @param {object} state - Vuex state (not used in this getter)
     * @param {object} obj - Object containing getters
     * @param {Function} obj.getMlValue - Function to get mlValue using mlKey
     * @param {object} obj.selectedClause - Object containing mlKey and mlValueIndex
     * @return {object|undefined} The selected paragraph or undefined if not found
     */
    selectedParagraph(state, { getMlValue, selectedClause }) {
      const { mlKey, mlValueIndex } = selectedClause;
      return getMlValue(mlKey)?.[mlValueIndex];
    },
    /**
     * Page where the selected paragraph starts.
     * @param {object} state - vuex state to read from (not used in this getter)
     * @param {object} obj - Object containing getters
     * @param {object} obj.selectedParagraph - selected paragraph
     * @return {number|undefined} - page number where selected paragraph starts, or undefined if not available
     */
    selectedParagraphStartingPage(state, { selectedParagraph }) {
      return selectedParagraph?.locations?.[0]?.page;
    },
    projectFields: state => state.projectFieldsData,

    /**
     * Gets document segments
     * @param {object} state - state from this module (not used in this getter).
     * @param {object} obj - Object containing getters
     * @param {object} obj.rawDocument - rawDocument from getters.
     * @return {Array<object>} array of doc_json segments
     */
    segments(state, { rawDocument }) {
      return rawDocument?.extracted_document?.doc_json?.data?.segments ?? [];
    },

    /**
     * Gets visible ordered fields
     * @param {object} state - The module state.
     * @param {object} getters - The module getters.
     * @param {Function} getters.getVisibleFields - Gets visible fields based on preferences and vuex state
     * @param {object} rootGetters - All getters.
     * @param {Array<object>} rootGetters.validProjectPreferences - user preference for the fields
     * @return {Array<object>} array of ordered clauses and project fields
     */
    visibleFields(state, { getVisibleFields }, rootState, rootGetters) {
      return getVisibleFields(rootGetters['project/layout/validProjectPreferences']);
    },

    /**
     * Gets ml keys of visibleFields and keys that can't be hidden (that are in Document Overview AKA specialClauses).
     * @param {Array<object>} visibleFields - fields visible for the document.
     * @param {Array<object>} specialClauses - clauses that can't be hidden.
     * @returns {Array<string>} Array of ml_key values.
     */
    visibleMlKeys(state, { visibleFields, specialClauses }) {
      return [...visibleFields, ...specialClauses].map(field => field.ml_key);
    },
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    setDocumentValue(state, { key, value }) {
      const rawDocument = getRawDocumentFromState(state);
      // rawDocument values assigned to state by reference.
      Vue.set(rawDocument, key, value);
      state.mlJsonDataExplicitUpdate += 1;
    },
    /**
     * Sets information about status of reprocess calls.
     * @param {object} state - vuex state to mutate.
     * @param {number} callsCountChange - 1 when we start a new api/reprocess call, -1 when it's done.
     */
    reprocessCallTracker(state, callsCountChange) {
      state.reprocessCallsInProgress += callsCountChange;
    },
    /**
     * Sets project tags in state.
     * @param {object} state - vuex state to mutate.
     * @param {Array<object>} tags - tags to set.
     */
    setProjectTags(state, tags) {
      state.projectTags = tags;
    },
    /**
     * Removes project tag from state.
     * @param {object} state - vuex state to change.
     * @param {number} id - id of tag to remove.
     */
    deleteProjectTag(state, id) {
      state.projectTags = state.projectTags.filter(tag => tag.id !== id);
    },
    /**
     * Sets project tags in state.
     * @param {object} state - vuex state to mutate.
     * @param {Array<object>} fileTags - fileTags to set.
     */
    setFileTags(state, fileTags) {
      state.fileTags = fileTags;
    },
    addExamples(state, examples) {
      state.examples = state.examples.concat(examples);
    },
    /**
     * Clears collapsed paragraphs array making all paragraphs expanded.
     * @param {object} state - vuex state to mutate.
     */
    clearCollapsedParagraphs(state) {
      state.collapsedParagraphs = [];
    },
    deleteExample(state, example) {
      state.examples = state.examples.filter(({ id }) => example.id !== id);
    },
    setExamples(state, examples) {
      state.examples = examples;
    },
    setDeleted: (state, isDeleted) => {
      state.isDeleted = isDeleted;
    },
    /**
     * Sets DiligenPdfViewer - instance of extended PdfViewer class from pdfjs.
     * @param {object} state - vuex state to mutate.
     * @param {object} viewer - viewer that drives the Original and OCR views.
     */
    setDiligenPdfViewer(state, viewer) {
      state.diligenPdfViewer = viewer;
    },
    setIsFindBarOpened(state, isOpened) {
      state.isFindBarOpened = isOpened;
    },
    /**
     * Sets PdfFindBar - instance of PDFFindBar class from pdfjs.
     * @param {object} state - vuex state to mutate.
     * @param {object} findBar - findBar mounted on sdv page.
     */
    setPdfFindBar(state, findBar) {
      state.pdfFindBar = findBar;
    },
    /**
     * Sets if the query (find command) is a search for extracted paragraph or a regular pdfFindBar search.
     * @param {object} state - vuex state to mutate.
     * @param {boolean} isParagraphQuery - true if we are looking for extracted paragraph.
     */
    setIsParagraphQuery(state, isParagraphQuery) {
      state.isParagraphQuery = isParagraphQuery;
    },
    setOcr(state, isOcr) {
      state.isOcrPdf = isOcr;
    },
    /**
     * Sets selectedClause that we then use to scroll to the Html that represents this clause in the UI
     * @param  {object} state - vuex state that is mutated
     * @param  {object} payload - The mutation payload.
     * @param  {string} payload.mlKey - ml key of the clause that is selected
     * @param  {number} payload.mlValueIndex - index that indicates which paragraph in the extracted text is selected
     * @param  {object} payload.$router - vuex router to which we will push new hash representing selected clause
     * @param  {string} payload.origin - where did the mutation happen ('plain-text', 'url', 'extracted-clause')
     */
    selectClause(state, { mlKey, mlValueIndex, $router, origin }) {
      state.selectedClauseOrigin = origin;
      const hash = toHashString(mlKey, mlValueIndex);
      const hasHashChanged = $router.currentRoute.hash.substring(1) !== hash;
      // Only call $router.replace when hash changes. It's not the case when user clicks on same paragraph twice.
      // Without this guard NavigationDuplicated error is thrown.
      if (hasHashChanged) {
        $router.replace({ query: $router.currentRoute.query, hash });
      }

      // General emit used by PlainText that allows custom logic when we "jump to text".
      state.selectClauseBus.$emit('clause-selected');
      // Emit that works together with ScrollOnClauseSelected directive.
      state.selectClauseBus.$emit(`clause-selected:${hash}`);
    },
    /**
     * Saves information about the paragraph being expanded or collapsed.
     * We keep this data in vuex so that it survives re-creating volatile plain-text pages
     * which are shown and tore down using IntersectionObserver check.
     * @param {object} state - vuex state to mutate.
     * @param {object} options
     * @param {object} options.paragraph - paragraph to set as expanded or collapsed.
     * @param {boolean} options.expanded - true to expand paragraph, false to collapse it.
     */
    setExpandedParagraph(state, { paragraph, expanded }) {
      if (!expanded) {
        state.collapsedParagraphs.push(paragraph);
      }
      else {
        state.collapsedParagraphs = state.collapsedParagraphs.filter(collapsed => collapsed !== paragraph);
      }
    },
    /**
     * Sets pdf visibility - if true it means we are showing original or ocr, if false - plain text.
     * Note that trying to set visibility for IE is silently ignored - PDF view is not available for IE since pdfjs 2.4.
     * @param  {object} state - vuex state that is mutated
     * @param  {object} options - vuex state that is mutated
     * @param {boolean} options.isVisible - are we displaying the pdf ?
     * @param {number} options.page - if we want to display the pdf - show this page.
     */
    setPDFVisibility(state, { isVisible, page }) {
      if (isVisible && !canBrowserLoadPdf) {
        return false; // PDF view is not available for this browser
      }
      state.isPDFVisible = isVisible;
      state.currentPage = page;
    },
    /**
     * Adds a clause
     * @param {object} state - vuex state to mutate
     * @param {object} clause - clause to add
     */
    addClause(state, clause) {
      state.clauses.push(clause);
    },
    /**
     * Sets clauses
     * @param {object} state - vuex state to mutate
     * @param {Array} clauses
     */
    setClauses(state, clauses) {
      state.clauses = clauses;
    },
    /**
     * Sets sdv socket in state
     * @param {object} store - vuex store to mutate
     */
    setSdvSocket(state, socket) {
      state.socket = socket;
    },
    /**
     * Sets sdvInitiated flag when initial state is set up
     * @param {object} state - vuex state to mutate
     * @param {boolean} isInitiated - new value that states if sdv is initiated
     */
    setInitiated(state, isInitiated) {
      state.sdvInitiated = isInitiated;
    },
    /**
     * Sets projectFields
     * @param {object} state - vuex state to mutate
     * @param {Array<object>} projectFields
     */
    setProjectFields(state, projectFields) {
      state.projectFields = projectFields;
    },
    /**
     * Sets rawDocumentData
     * @param {object} state - vuex state to mutate
     * @param {object} rawDocumentData
     */
    setRawDocumentData(state, rawDocumentData) {
      // rawDocumentData gets frozen as it's a huge object and observing it is a big performance hit for big documents.
      // For documents with over few hundred pages SDV can become unusable and can freeze the browser.
      // All data except for mlJson data is immutable.
      // To mitigate the problem of updating components that are driven by changing mlJson
      // `mlJsonDataExplicitUpdate` state member has been introduced.
      // If you have a component that should be changed when mlJson changes
      // make sure to alter `mlJsonDataExplicitUpdate` along the `mlJson`
      // and key the component you want to re-render when the mutation happens with `:key=mlJsonDataExplicitUpdate`.
      // Getters that need to reevaluate when mlJson changes must call `mlJsonDataExplicitUpdate` explicitly.
      if (rawDocumentData.file_details) {
        Object.freeze(rawDocumentData.file_details.extracted_document);
      }
      state.rawDocumentData = rawDocumentData;
      document.title = isEmpty(rawDocumentData) ? 'Diligen' : getFileNameFromState(state);
    },
    setMappedData(state, mappedData) {
      state.mappedData = { ...mappedData };
    },
    /**
     * Sets userReviewStatus
     * @param {object} state - vuex state to mutate
     * @param {string} userReviewStatus
     */
    setUserReviewStatus(state, userReviewStatus) {
      state.userReviewStatus = userReviewStatus;
    },
    /**
     * Sets documentReviews
     * @param {object} state - Vuex state to mutate.
     * @param {Array} documentReviews - Array of document reviews.
     */
    setDocumentReviews(state, documentReviews) {
      state.documentReviews = documentReviews;
    },
    /**
     * Shows main loading that overlays contents with given message and opacity.
     * @param {object} state - vuex state to mutate.
     * @param {object} payload - The mutation payload.
     * @param {string} [payload.message] - what message to display when busy.
     * @param {number} [payload.opacity] - how much would we want to cover the contents.
     */
    showMainLoading: (state, { message = 'Loading ...', opacity = 1 } = {}) => {
      state.mainLoadingOpacity = opacity;
      state.mainLoadingMessage = message;
    },
    /**
     * Update state with new relationship data between the file and tags. Push new project tags to those in state.
     * @param {object} state - vuex state to mutate.
     * @param {object} data
     * @param {Array<object>} data.newProjectTags - Array of newly created tags for this project that should be applied.
     * @param {Array<number>} data.applied - Array of ids of tags applied to this file.
     * @param {Array<number>} data.unapplied - Array of ids of tags unapplied from this file.
     * @param {number} data.fileId - id of this file - passed to mutation as can't access getters here.
     */
    updateFileTags(state, { newProjectTags = [], applied = [], unapplied = [], fileId }) {
      state.projectTags.push(...newProjectTags);
      const appliedAndNew = [...applied, ...newProjectTags.map(tag => tag.id)];
      state.fileTags = state.fileTags.filter(fileTag => !unapplied.includes(fileTag.tag_id));
      const alreadyAppliedIds = state.fileTags.map(fileTag => fileTag.tag_id);
      const newFileTags = appliedAndNew
        .filter(tagId => !alreadyAppliedIds.includes(tagId))
        .map(tag_id => ({ tag_id, file_id: fileId }));
      state.fileTags.push(...newFileTags);
    },
    /**
     * Hides main loading
     * @param {object} state - vuex state to mutate
     */
    hideMainLoading: state => {
      state.mainLoadingOpacity = 0;
      state.mainLoadingMessage = null;
    },
    /**
     * Adds projectField to state
     * @param {object} state - vuex state to mutate
     * @param {object} projectField to add
     */
    addProjectField(state, projectField) {
      state.projectFields.push({
        ...projectField,
        values: [],
      });
    },
    UPDATE_OVERVIEW: (state, { payload, updatedClauses }) => {
      const rawDocument = getRawDocumentFromState(state);
      // rawDocument values assigned to state by reference
      if ('executionStatus' in payload) {
        rawDocument.execution_status = payload.executionStatus;
      }
      if ('folder' in payload) {
        rawDocument.folder = payload.folder;
      }
      Object.entries(updatedClauses).forEach(([key, mlValue]) => UPDATE_CLAUSE(state, { key, mlValue }));
    },

    START_EDIT_FIELD: (state, key) => {
      state.fieldsBeingEdited.push(key);
    },

    END_EDIT_FIELD: (state, fieldKey) => {
      state.fieldsBeingEdited = state.fieldsBeingEdited.filter(key => key !== fieldKey);
      state.fieldsBeingEditedRemote = state.fieldsBeingEditedRemote.filter(key => key !== fieldKey);
    },

    BLOCK_EDIT_FIELD: (state, fieldKey) => {
      state.fieldsBeingEditedRemote.push(fieldKey);
    },

    UPDATE_CLAUSE,

    /**
     * Updates project field in state
     * @param  {object} state - vuex state to mutate
     * @param  {object} payload - The mutation payload.
     * @param  {number} payload.project_field_id - project field id to update
     * @param  {string[]} payload.values - new project field values
     * @param  {boolean} payload.overwrite - If the new values overwrite the existing.
     * we need to set it up if there was no value for this project field prior
     */
    updateProjectField(state, { project_field_id, values, overwrite = true }) {
      // projectField properties assigned to state by reference:
      const projectField = getProjectField({ projectFields: state.projectFields, id: project_field_id });
      projectField.values = overwrite ? values : [...projectField.values, ...values];
    },

    /**
     * Commits 1 to out_of_scope so that the value is the same as the one that comes form the API
     * @param {object} state - vuex state to mutate
     */
    MARK_OUT_OF_SCOPE: state => {
      // rawDocument value assigned to state by reference
      getRawDocumentFromState(state).out_of_scope = 1;
    },

    /**
     * Commits 0 to out_of_scope so that the value is the same as the one that comes form the API
     * @param {object} state - vuex state to mutate
     */
    MARK_IN_SCOPE: state => {
      // rawDocument value assigned to state by reference
      getRawDocumentFromState(state).out_of_scope = 0;
    },

    setFileData(state, fileData) {
      state.fileData = fileData;
    },

    setPendingFileRequest(state, value) {
      state.pendingFileRequest = value;
    },

    addToRecentlyTrained({ recentlyTrainedClauseIds }, clauseId) {
      const presentIndex = recentlyTrainedClauseIds.findIndex(id => clauseId === id);
      if (presentIndex >= 0) {
        // Move clause id to the front if it already exists.
        recentlyTrainedClauseIds.unshift(recentlyTrainedClauseIds.splice(presentIndex, 1)[0]);
      }
      else {
        // Otherwise append to the front and remove the last item if there are more than three.
        recentlyTrainedClauseIds.unshift(clauseId);
        if (recentlyTrainedClauseIds.length > 3) {
          recentlyTrainedClauseIds.pop();
        }
      }
    },

    setTotalPages(state, pageCount) {
      state.totalPages = pageCount;
    },
  },
  /* eslint-enable no-param-reassign */
  actions: {
    cancelEditField({ commit, state, getters }, key) {
      state.socket.emit('CANCEL_EDIT_FIELD', { key, fileId: getters.fileId });
      commit('END_EDIT_FIELD', key);
    },
    cancelAllFieldEdits({ dispatch, state }) {
      state.fieldsBeingEdited.forEach(key => dispatch('cancelEditField', key));
    },
    /**
     * Deletes an example and updates the state by committing the change.
     */
    async deleteExample({ commit }, example) {
      await axios.delete(`/api/examples/${example.id}`);
      commit('deleteExample', example);
    },
    /**
     * Close socket for current SDV (happens when leaving sdv route)
     * @param {object} socket - socket from socket.io to disconnect
     */
    disconnectSdvSocket({ state: { socket } }) {
      if (socket) {
        socket.disconnect();
      }
    },
    /**
     * GET clauses
     * @param {object} context - The action context.
     * @param {Function} context.commit - function calling vuex state mutations
     */
    async fetchClauses({ commit }) {
      const { data } = await axios.get('/api/clauses');
      commit('setClauses', data.data);
    },
    /**
     * GET project fields
     * @param {object} context - The action context.
     * @param {Function} context.commit - function calling vuex state mutations
     * @param {object} context.getters - vuex getters to read
     */
    async fetchProjectFields({ commit, getters }) {
      const { data } = await axios.get(`/api/documents/${getters.fileId}/projectFields`);
      commit('setProjectFields', data.data.fields.sort(nameCompare));
    },
    /**
     * Gets raw document data
     * @param {object} context - The action context.
     * @param {Function} context.commit - Commit state.
     * @param {object} context.getters - The module getters.
     * @param {number} context.getters.fileId - id of this file
     */
    async fetchRawDocumentData({ commit, getters: { fileId } }) {
      const { data } = await axios.get(`/api/documents/${fileId}/details`, {
        params: { ml_json: false },
      });
      commit('setRawDocumentData', data.data);
    },
    /**
     * Get the mapped document data (clauses and project fields) for the current file.
     * @param {object} context - The action context.
     * @param {Function} context.commit - Commit state.
     * @param {object} context.getters - The module getters.
     * @param {number} context.getters.fileId - id of this file
     */
    async fetchMappedData({ commit, getters: { fileId } }) {
      const { data } = await axios.get(`/api/documents/${fileId}/mappedDetails`);
      commit('setMappedData', data.data);
    },
    /**
     * Gets and commits tags for project this file belongs to.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function calling vuex state mutations.
     * @param {object} context.getters - The module getters.
     * @param {number} context.getters.projectId - ID of the project.
     * @returns {Promise<Array<object>>} - fresh tags.
     */
    async fetchProjectTags({ commit, getters: { projectId } }) {
      const { data } = await axios.get(`/api/projects/${projectId}/tags`);
      const tags = data.data;
      commit('setProjectTags', tags);
      return tags;
    },
    /**
     * Gets and commits fileTags for this file - fileTags represent relationship with tags.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function calling vuex state mutations.
     * @param {object} context.getters - The module getters.
     * @param {number} context.getters.fileId - ID of the file.
     * @returns {Promise<Array<object>>} - fresh fileTags.
     */
    async fetchFileTags({ commit, getters: { fileId } }) {
      const { data } = await axios.get(`/api/documents/${fileId}/tags`);
      const fileTags = data.data;
      commit('setFileTags', fileTags);
      return fileTags;
    },
    /**
     * Gets user review status
     * @param {object} context - The action context.
     * @param {Function} context.commit - function calling vuex state mutations
     * @param {object} context.getters - vuex getters to read
     * @param {number} context.getters.fileId - ID of the file.
     */
    async fetchUserReviewStatus({ commit, getters: { fileId } }) {
      const url = `/api/documents/${fileId}/reviewStatus`;
      const { data } = await axios.get(url);
      commit('setUserReviewStatus', data.data.review_status);
    },
    /**
     * Get the file data and encode it as base64.
     * @param {object} context - The action context.
     * @param {Function} context.commit - Commit state.
     * @param {object} context.getters - The module getters.
     * @param {boolean} context.getters.converted - If this document is a pdf converted from a docx document.
     * @param {number} context.getters.projectId - Id of the project this document belongs to.
     * @param {number} context.getters.fileId - Id of the file.
     * @param {object} context.state - The module state.
     * @param {boolean} context.state.isOcrPdf - If the file should be the OCR version.
     * @param {import('superagent').SuperAgentRequest} [context.state.pendingFileRequest] - The pending file request.
     */
    // eslint-disable-next-line complexity
    async fetchFileData({ commit, getters: { converted, projectId, fileId }, state }) {
      if (!projectId || !fileId) {
        return null;
      }

      // Cancel the previous file request if one is being waited on
      // This prevents files loading into SDV out of order if a previously requested file takes long to download
      if (state.pendingFileRequest) {
        state.pendingFileRequest.abort();
        commit('setPendingFileRequest', null);
      }

      const query = {};
      if (state.isOcrPdf) {
        query.ocred = 'true';
      }
      // if original pdf and converted
      else if (converted === true) {
        query.converted = 'true';
      }

      const fileRequest = request
        .get(`/api/documents/${fileId}/content`)
        .query(query)
        .responseType('blob');

      commit('setPendingFileRequest', fileRequest);

      try {
        const { body: blob } = await fileRequest;
        commit('setPendingFileRequest', null);
        const fileData = await blobToBase64(blob);
        commit('setFileData', fileData);
      }
      catch (error) {
        // Consume aborted errors as they are expected and throw anything else
        if (error.code !== ABORTED) {
          throw error;
        }
      }
    },
    initSdvSocket({ dispatch }) {
      dispatch('sdvSocketHandler', getSocket());
    },
    async getExamplesForFile({ commit, getters }) {
      const { data } = await axios.get(`/api/documents/${getters.fileId}/examples`);
      commit('setExamples', data.data.examples);
    },
    /**
     * Get reviews for the SDV document.
     * @param {object} context - The action context.
     * @param {Function} context.commit - Function calling vuex state mutations.
     * @param {{ fileId: number }} context.getters - The module getters.
     */
    async fetchDocumentReviews({ commit, getters: { fileId } }) {
      const { data } = await axios.get(`/api/documents/${fileId}/reviews`);
      commit('setDocumentReviews', data.data.reviews);
    },
    /**
     * Fetch sdv data
     *
     * @param {object} context - vuex action context.
     * @param {Function} context.dispatch - Dispatch actions.
     * @param {Function} context.commit - Commit state.
     * @param {object} context.getters - vuex getters to read.
     * @param {object} payload - The action payload.
     * @param {boolean} [payload.isProjectChanging] - False if we stay on the same project (moving to the next file).
     * @param {boolean} [payload.preferPlainText] - If plain text should be shown first.
     */
    async initSdvPage({ dispatch, commit, getters }, { isProjectChanging = true, preferPlainText = false } = {}) {
      await Promise.all([
        dispatch('fetchRawDocumentData'),
        dispatch('fetchMappedData'),
      ]);
      commit('clearCollapsedParagraphs');
      commit('setOcr', getters.isOCRAvailable);

      const promises = [
        dispatch('fetchFileTags'),
        dispatch('fetchUserReviewStatus'),
        dispatch('fetchDocumentReviews'),
        dispatch('fetchProjectFields'),
        dispatch('getExamplesForFile'),
        dispatch('fetchFileData'),
        dispatch('project/layout/fetchUserLayout', {}, { root: true }),
      ];

      if (isProjectChanging) {
        promises.push(
          dispatch('project/FETCH_PROJECT', { projectId: getters.projectId }, { root: true }),
          dispatch('fetchProjectTags'),
          dispatch('fetchClauses'),
          dispatch('permissions/fetchProjectPermissions', getters.projectId),
          dispatch('project/layout/fetchProjectLayoutTemplate', {}, { root: true }),
        );
      }

      await Promise.all(promises);
      // We set the page on which pdf will be opened to the page where the selected paragraph is.
      const page = getters.selectedParagraphStartingPage || 1;
      commit('setPDFVisibility', { isVisible: !preferPlainText, page });
      commit('setInitiated', true);
    },

    pdfClicked({ commit, dispatch }, { changeToOcr, page }) {
      commit('setPDFVisibility', { isVisible: true, page });
      commit('setOcr', changeToOcr);
      commit('setFileData', null);
      dispatch('fetchFileData');
    },
    /**
     * Reprocesses a single document from SDV
     * @param {object} context - The action context.
     * @param {Function} context.commit - Commit changes to state.
     * @param {Function} context.dispatch - function calling other vuex actions
     * @param {{ fileId: Number, projectId: Number }} context.getters - The module getters.
     */
    async reprocessDocument({ commit, dispatch, getters: { fileId, projectId } }, { rules_only }) {
      try {
        commit('reprocessCallTracker', 1);
        await axios.post(`/api/projects/${projectId}/reprocess`, {
          fileIds: [fileId],
          rules_only,
        });
        await dispatch('fetchRawDocumentData');
        await dispatch('fetchMappedData');
      }
      finally {
        commit('reprocessCallTracker', -1);
      }
    },

    /**
     * Post training data and update the state with the new examples and recently trained clause.
     */
    async postTrainingData({ commit, getters: { fileId, projectId } }, payload) {
      const response = await axios.post('/api/examples', {
        ...payload,
        file_id: fileId,
        project_id: projectId,
      });

      commit('addExamples', response.data.data.examples);
      commit('addToRecentlyTrained', payload.clause_id);
    },

    sdvSocketHandler,

    /**
     * Deletes the document.
     * @param {object} context
     * @param {Function} context.commit - function calling vuex mutations
     * @param {object} context.getters - The module getters.
     * @param {number} context.getters.fileId - id of this file
     */
    async deleteDocument({ commit, getters: { fileId } }, { keep_model_examples }) {
      commit('showMainLoading', { message: 'Deleting ...', opacity: 0.9 });
      try {
        await axios.delete('/api/documents', {
          data: { file_ids: [fileId], keep_model_examples },
        });
        commit('hideMainLoading');
        commit('setDeleted', true);
      }
      catch (error) {
        Vue.diligenToast.showError(getErrorMessage(error));
      }
      finally {
        commit('hideMainLoading');
      }
    },
    /**
     * Updates relationship between this file and tags.
     * @param {object} context
     * @param {Function} context.commit - function calling vuex mutations.
     * @param {object} context.getters - The module getters.
     * @param {number} context.getters.fileId - id of this file.
     * @param {object} context.state - vuex sdv state.
     * @param {object} data
     * @param {Array<number>} data.applied - Array of ids of tags applied to this file.
     * @param {Array<number>} data.unapplied - Array of ids of tags unapplied from this file.
     * @param {Array<object>} data.createdAndApplied - Array of new tags that should be created and applied.
     * @returns {Promise}
     */
    async updateFileTags({ commit, getters: { fileId }, state }, data) {
      const { applied = [], unapplied = [], createdAndApplied = [] } = data;
      const { body: { data: { tags } } } = await request
        .post('/api/files_tags')
        .set('clientID', state.socket.id)
        .send({
          unapplied,
          applied,
          createdAndApplied,
          fileIds: [fileId],
        });
      commit('updateFileTags', {
        newProjectTags: tags,
        applied,
        unapplied,
        fileId,
      });
    },

    async UPDATE_REVIEW_STATUS({ commit, getters: { fileId, projectId } }, reviewStatus) {
      const statusEndpointMap = {
        REVIEWED: 'markReviewed',
        OUT_OF_SCOPE: 'markOutOfScope',
        IN_SCOPE: 'markInScope',
        PENDING: 'unmarkReviewed',
      };
      const endpoint = statusEndpointMap[reviewStatus];
      if (!endpoint) {
        throw new Error(`Invalid reviewStatus: ${reviewStatus}`);
      }
      await axios.post(`/api/documents/${endpoint}`, {
        fileIds: [fileId],
        reviewStatus,
        projectId,
      });
      if (reviewStatus === OUT_OF_SCOPE) {
        commit('MARK_OUT_OF_SCOPE');
      }
      else {
        commit('MARK_IN_SCOPE');
      }
      commit('setUserReviewStatus', reviewStatus);
    },

    START_EDIT_FIELD: ({ commit, state, getters }, { key }) => {
      commit('START_EDIT_FIELD', key);
      state.socket.emit('START_EDIT_FIELD', {
        key,
        editBy: state.socket.id,
        fileId: getters.fileId,
      });
    },

    UPDATE_CLAUSE: async ({ commit, state, getters }, update) => {
      await axios.patch(`/api/documents/${getters.fileId}`, {
        data: {
          attributes: {
            updated_clauses: { [update.key]: update.mlValue },
          },
        },
        meta: { clientID: state.socket.id },
      });

      // Create a new mappedData object to ensure reactivity
      // ml_key can exist in database and be shown in UI to user, but not have an ml_key in ml_json from mappedDetails
      const newMappedData = { ...state.mappedData, [update.key]: update.mlValue };
      commit('setMappedData', newMappedData);
      commit('END_EDIT_FIELD', update.key);
    },

    async UPDATE_FIELD({ state, getters: { fileId } }, { projectFieldId, values }) {
      await axios.put(`/api/documents/${fileId}/projectFields/${projectFieldId}`, {
        values,
        clientID: state.socket.id,
      });
    },
  },
  modules: {
    permissions: {
      namespaced: true,
      ...permissionsModule,
    },
  },
};
