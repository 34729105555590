// Constant time for throttled requests/actions.
// Used for actions like `fetch documents` so that GET /api/documents
// Is called max one time per second. There is no point to do it more often.
// Throttling is needed as there might be lots of notifications comming in for a given project
// that tell the client that state should be refereshed with a given api call.
const THROTTLE_TIME = 1000;

export {
  THROTTLE_TIME,
};
