<template>
  <div class="header">
    <router-link to="/projects" :class="{ 'mr-auto': !loggedInUser.isSubscribed }">
      <img class="logo" src="https://d455oxs41ely6.cloudfront.net/img/logo-grey-small.png">
    </router-link>
    <div v-if="loggedInUser.isSubscribed" class="navigation mr-auto">
      <router-link to="/projects">Projects</router-link>
      <router-link to="/training">Training</router-link>
    </div>
    <SubscriptionNotifier :logged-in-user="loggedInUser" />
    <CollapsableMenu v-if="isLoggedInLoaded" :left-aligned="true">
      <template #handle="{ collapsed }">
        <div :class="{ expanded: !collapsed }" class="icon-and-name">
          <ProfileSvg class="user-icon" name="user" />
          <div class="user-name">{{ loggedInUser.name }}</div>
        </div>
      </template>
      <template #items="{ menuItem }">
        <component :is="menuItem" tag-name="router-link" to="/profile">Profile</component>
        <component
          :is="menuItem"
          v-if="loggedInUser.isSubscribed && loggedInUser.permissions.includes('modify_user')"
          tag-name="router-link"
          to="/team"
        >
          Team
        </component>
        <component
          :is="menuItem"
          v-if="loggedInUser.permissions.includes('modify_subscription')"
          tag-name="router-link"
          to="/subscription"
        >
          Subscription
        </component>
        <component
          :is="menuItem"
          v-if="loggedInUser.isSubscribed && loggedInUser.permissions.includes('modify_subscription')"
          tag-name="router-link"
          to="/billing"
        >
          Billing
        </component>
        <component
          :is="menuItem"
          v-if="loggedInUser.isSubscribed"
          tag-name="router-link"
          to="/integrations"
        >
          Integrations
        </component>
        <!-- hidden for now - when rules are ready/tested we will reveal this in userland.
        <component :is="menuItem"
                   v-if="loggedInUser.isSubscribed"
                   tag-name="router-link"
                   to="/rules"
        >
          Rules
        </component>
        -->
        <component
          :is="menuItem"
          v-if="loggedInUser.isSubscribed"
          tag-name="a"
          href="/api-documentation"
          target="_blank"
        >
          Developer API
        </component>

        <component :is="menuItem" class="pointer" @click.native.prevent="logout">Logout</component>
      </template>
    </CollapsableMenu>
  </div>
</template>

<script>
import SubscriptionNotifier from './DiligenNavSubscriptionNotifier.vue';
import jwtUtils from 'helpers/jwt-utils';
import ProfileSvg from 'svg/Profile.vue';

export default {
  components: {
    ProfileSvg,
    SubscriptionNotifier,
  },
  props: {
    loggedInUser: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLoggedInLoaded() {
      return !!this.loggedInUser.userId;
    },
  },
  methods: {
    logout() {
      jwtUtils.clear();
      window.location = '/login'; // hard reload to remove memory state
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  height: $header-height;
}

.logo {
  margin-right: 45px;
  width: 105px;
}

.navigation a {
  font-size: 14px;
  margin-right: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: $color-nav-link;
}

.icon-and-name {
  height: 32px;
  border-radius: 16.5px;
  display: flex;
  align-items: center;
  color: $color-default-text;
  padding: 0 12px;
  border: 1px solid $color-account-dropdown-border;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: -0.2px;

  &:hover {
    border-color: $color-border-diligen-input-hover;
    color: $color-link-primary;
  }

  &.expanded {
    border-color: $color-secondary-interaction;
    color: $color-link-primary;
  }
}

.user-icon {
  width: 15px;
  height: 15px;

  @include xs {
    margin-right: 12px;
  }
}

.user-name {
  display: none;

  @include xs {
    display: block;
  }
}

.pointer {
  cursor: pointer;
}
</style>
