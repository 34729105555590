const ORIGINAL = 'Original';
const OCR = 'OCR';
const TEXT = 'Text';
const SEARCH_ENABLED = 'Search Enabled';

export {
  ORIGINAL,
  OCR,
  TEXT,
  SEARCH_ENABLED,
};
