import { uniq } from 'lodash';

const groupClausesByCategory = (clauses) => {
  const sortedClauses = clauses
    .slice()
    .sort((a, b) => a.name.trim().localeCompare(b.name.trim()));

  const clauseCategories = uniq(sortedClauses.map((clause) => clause.category));

  return clauseCategories.sort().map((category) => ({
    groupLabel: category,
    groupOptions: sortedClauses.filter((clause) => clause.category === category),
  }));
};

export default groupClausesByCategory;
