<template>
  <component
    :is="tagName"
    v-bind="$attrs"
    class="menu-item primary-link"
    @click="$parent.collapse"
    @click.native="$parent.collapse"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="scss" scoped>
  .menu-item {
    white-space: nowrap;
    margin: 12px;
    font-size: 14px;
    display: block;
  }
</style>
