/**
 * Checks if the argument is a number or a string that can be parsed as number.
 * Added to deal with query params which are always strings but can be casted to their "real" type.
 * based on angular implementation:
 * https://github.com/angular/angular/blob/master/packages/common/src/pipes/number_pipe.ts#L235
 * @param  {string|number} str - a string to parse or a number
 * @return {boolean}
 */
const isNumeric = (str) => !Number.isNaN(Number(str) - parseFloat(str));

export { isNumeric };
