import { isEqual } from 'lodash';

function createEmptyFilter() {
  return {
    inScope: null, // filter needs to be null if we want to ignore it on the backend
    unassigned: null,
    unreviewed: null,
    // advanced filters
    reviewers: [],
    contractName: null,
    documentTypes: [],
    fileName: null,
    folders: [],
    governingLawPlaces: [],
    parties: [],
    reviewStatuses: [],
    executionStatuses: [],
    fileId: null,
    tagIds: [],
    allTagIds: true,
    inverseTagIds: [],
    textString: null,
    clauses: [],
    projectFieldValues: [],
  };
}

function noFiltersApplied({ filter }) {
  return isEqual(filter, createEmptyFilter());
}

/**
 * Sets a filter property with the given value. If the value is `false`, it sets the property to `undefined`.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.filter - The existing filter object.
 * @param {string} options.name - The name of the filter property to set.
 * @param {*} options.value - The value to set the filter property to.
 * @returns {Object} - The new filter object with the updated property.
 */
function setFilter({ filter, name, value }) {
  return {
    ...filter,
    [name]: value === false ? undefined : value,
  };
}

function togglePrimitiveFilterProperty({ filter, name }) {
  const newValue = filter[name] ? null : true;
  return setFilter({ filter, name, value: newValue });
}

function addReviewerToFilter({ filter, id, resetOptions = false }) {
  let newFilter = filter;
  if (resetOptions) {
    newFilter = setFilter({ filter, name: 'inverseReviewers', value: false });
  }
  const reviewers = newFilter.reviewers;
  return setReviewerFilter({ filter: newFilter, reviewers: [...reviewers, id] });
}

function removeReviewerFromFilter({ filter, id }) {
  const reviewers = filter.reviewers;
  return setReviewerFilter({ filter, reviewers: reviewers.filter((x) => x !== id) });
}

function setReviewerFilter({ filter, reviewers }) {
  let newFilter = setFilter({ filter, name: 'reviewers', value: reviewers });
  if (newFilter.reviewers.length > 0) {
    newFilter = setFilter({ filter: newFilter, name: 'unassigned', value: null });
  }
  return newFilter;
}

function toggleUnassigned({ filter }) {
  let newFilter = togglePrimitiveFilterProperty({ filter, name: 'unassigned' });
  if (newFilter.unassigned) {
    newFilter = setFilter({ filter: newFilter, name: 'reviewers', value: [] });
    newFilter = setFilter({ filter: newFilter, name: 'inverseReviewers', value: false });
  }
  return newFilter;
}

export {
  addReviewerToFilter,
  createEmptyFilter,
  noFiltersApplied,
  removeReviewerFromFilter,
  setFilter,
  setReviewerFilter,
  togglePrimitiveFilterProperty,
  toggleUnassigned,
};
