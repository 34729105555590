<template>
  <!-- eslint-disable max-len, vue/html-indent, vue/html-closing-bracket-spacing -->
  <svg class="svg-component" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
    <path id="a" d="M23.9 21.854c.13.27.138.57-.002.777a.799.799 0 0 1-.649.369H.74c-.284 0-.54-.138-.652-.376a.887.887 0 0 1 .001-.77L11.346 1.37A.799.799 0 0 1 11.994 1c.284 0 .54.138.652.374L23.9 21.854zM12.04 3.218L1.926 21.48h20.052L12.04 3.218zm.838 15.27h.126c0-1.402-2.017-1.402-2.017 0 0 1.404 2.017 1.404 2.017 0h-.126zm-.167-4.062c-.126 1.332-.312 2.044-.715 2.044-.452 0-.64-.771-.748-2.251a39.024 39.024 0 0 1-.068-1.286c-.099-1.823-.113-2.35-.048-2.903.085-.725.345-1.082.864-1.082.891 0 1.021 1.002.815 3.986a31.775 31.775 0 0 1-.1 1.492z"/>
  </svg>
</template>

<script>
export default {
  name: 'AlertSvg',
};
</script>
