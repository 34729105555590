import { throttle, get, map } from 'lodash';
import Vue from 'vue';
import { actionsIndicator, withProcessingIndicator } from './actions-in-progress';
import collaboratorModule from './collaborators';
import documentsModule from './documents';
import layoutModule from './layouts';
import permissionsModule from './permissions';
import filteringModule from './project-filters';
import tableModule from './project-table';
import reviewStatusModule from './review-status';
import { THROTTLE_TIME } from '@/constants/pv';
import uuid from 'helpers/client-uuid';
import axios from 'helpers/diligen-xhr-axios';
import { sort, filters } from 'helpers/documents-query-params';
import { downloadResponse } from 'helpers/dom-utils';
import reportUtils from 'helpers/report-utils';
import getSocket from 'helpers/socket-utils';
import { nameCompare } from 'helpers/sort-utils';
import { AVAILABLE_FEATURES, NON_DATA_FIELD_NAMES } from 'shared/constants/document-fields';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER } from 'shared/constants/project-page';
import {
  FILE_DELETED,
  FILE_STATUS_UPDATED,
  FILE_UPLOADED,
  FILE_UPDATED,
  LAYOUTS_DELETED,
  PROJECT_FIELD_CREATED,
  PROJECT_FIELD_DELETED,
  PROJECT_FIELD_UPDATE,
  REVIEW_UPDATE,
  REVIEWERS_UPDATED,
  TAGS_MODIFIED,
  TEAM_UPDATED,
} from 'shared/constants/project-update-reasons';
import { REVIEWED, PENDING, IN_SCOPE, OUT_OF_SCOPE } from 'shared/constants/review-status-codes';
import { isProcessed } from 'shared/processed-file';

/**
 * Creates a message we show in a toast about finished upload including duplicate info if we have it at hand.
 * @param {{ uniques: number, duplicates: number }} uploadedInfo - Object with uniques and duplicates count.
 * @return {string} Message with information about finished upload.
 */
const getFinishUploadMessage = uploadedInfo => {
  let message = 'Finished Uploading.';
  if (uploadedInfo) {
    const { uniques, duplicates } = uploadedInfo;
    message += ` Added ${uniques} ${uniques === 1 ? 'file' : 'files'}.`;
    if (uploadedInfo.duplicates) {
      message += ` Skipped ${duplicates} ${duplicates === 1 ? 'duplicate' : 'duplicates'}.`;
    }
  }
  return message;
};

export default {
  state: {
    initialSetup: true,
    clauses: [],
    eventBus: new Vue({}),
    file_info: {},
    project: {},
    show_documents_uploader: false,
    project_fields: [],
    isUploading: false,
    isUnzipping: false,
    socket: null,
    exampleCount: 0,
    clauseExampleCounts: [],
    tags: [],
    pageSize: DEFAULT_PAGE_SIZE,
    keylist: {},
  },
  getters: {
    project_id(state, getters, { route }) {
      return parseInt(route.params.projectId, 10);
    },
    /**
     * Get a function to determine page size and page number from the query and state.
     * @return {(query?: object) => { size: number, number: number }} Function used to get size and number of the page.
     */
    page(state, getters, rootState) {
      return query => ({
        size: get(query || rootState.route.query, 'page.size', state.pageSize),
        number: get(query || rootState.route.query, 'page.number', DEFAULT_PAGE_NUMBER),
      });
    },
    withDefaults(state, getters) {
      return query => ({ sort: sort(query), page: getters.page(query), filter: filters(query) });
    },
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    /**
     * Adds new project field to state
     * @param {object} state - vuex state to append to
     * @param {object} field - project field to add
     */
    addField(state, field) {
      state.project_fields.push(field);
    },
    /**
     * Adds new tag to state.
     * @param {object} state - vuex state to append to.
     * @param {object} tag - tag to add.
     */
    addTag(state, tag) {
      state.tags.push(tag);
    },
    /**
     * Removes project field from state
     * @param {object} state - vuex state to change
     * @param {number} id - id of project field to remove
     */
    deleteField(state, id) {
      state.project_fields = state.project_fields.filter(projectField => projectField.id !== id);
    },
    /**
     * Removes tag from state.
     * @param {object} state - vuex state to change.
     * @param {number} id - id of tag to remove.
     */
    deleteTag(state, id) {
      state.tags = state.tags.filter(tag => tag.id !== id);
    },
    /**
     * Updates a project field.
     * @param {object} state - vuex state to mutate.
     * @param {number} id - id of the project field to update.
     * @param {string} name - new name for the project field.
     * @param {object} field_type - new field_type for the project field.
     */
    updateProjectField(state, { id, name, field_type }) {
      const updatedField = state.project_fields.find(field => field.id === id);
      updatedField.name = name;
      updatedField.field_type = field_type;
    },
    /**
     * Updates a tag.
     * @param {object} state - vuex state to mutate.
     * @param {object} options
     * @param {number} options.id - id of the tag to update.
     * @param {...Object} options.data - data to patch.
     */
    updateTag(state, { id, ...data }) {
      const updatedTag = state.tags.find(tag => tag.id === id);
      Object.assign(updatedTag, data);
    },
    setPvSocket(state, socket) {
      state.socket = socket;
    },
    HIDE_DOCUMENTS_UPLOADER(state) {
      state.show_documents_uploader = false;
    },
    SET_CLAUSES(state, clauses) {
      state.clauses = clauses;
    },
    SET_PROJECT(state, project) {
      state.project = project;
    },
    SET_PROJECT_FIELDS(state, projectFields) {
      state.project_fields = projectFields;
    },
    ADD_PROJECT_FIELD(state, projectField) {
      state.project_fields = state.project_fields
        .concat(projectField)
        .sort(nameCompare);
    },
    SET_PROJECT_FILE_INFO(state, fileInfo) {
      state.file_info = fileInfo;
    },
    /**
     * Sets tags in vuex state.
     * @param {object} state - vuex state modify
     * @param {Array<object>} tags - tags to set.
     */
    setTags(state, tags) {
      state.tags = tags;
    },
    SHOW_DOCUMENTS_UPLOADER(state) {
      state.show_documents_uploader = true;
    },
    UPDATE_PROJECT(state, properties) {
      state.project = {
        ...state.project,
        ...properties,
      };
    },
    setIsUploading(state, isUploading) {
      state.isUploading = isUploading;
    },
    setIsUnzipping(state, isUnzipping) {
      state.isUnzipping = isUnzipping;
    },
    setExampleCount(state, count) {
      state.exampleCount = count;
    },
    setClauseExampleCounts(state, data) {
      state.clauseExampleCounts = data;
    },
    /**
     * Set the last selected page size to be used when loading the project overview.
     * @param {object} state - The module state.
     * @param {number} size - The selected page size.
     */
    setPageSize(state, size) {
      state.pageSize = size;
    },
    setKeyList(state, keylist) {
      state.keylist = keylist;
    },
  },
  /* eslint-enable no-param-reassign */
  actions: {
    /**
     * POST new project field and commit it to state
     * @param {object} context
     * @param {Function} context.commit - function calling vuex state mutations
     * @param {object} context.state - vuex state to read
     * @param {object} params
     * @param {string} params.name - name of the new project field to add.
     * @param {string} params.type - type of the new project field to add.
     * @param {Array<string>} [params.options] - possible values from which user can pick project field value.
     * @param {boolean} params.multi - whether this field can have multiple values checked.
     */
    async addProjectField({ commit, state }, { name, type, options, multi = false }) {
      const data = {
        name,
        field_type: { type, options, multi },
      };
      const response = await axios.post(`/api/projects/${state.project.id}/projectFields`, { data });
      commit('addField', response.data.field);
      commit('addEmptyProjectFieldData', response.data.field);
    },

    /**
     * Dispatches necessary actions (due on update reason) to fetch fresh state and commit it to the vuex store.
     * @param {object} context
     * @param {Function} context.commit - function calling vuex state mutations.
     * @param {Function} context.dispatch - function calling vuex actions.
     * @param {object} context.state - vuex state for this module.
     * @param {object} params
     * @param {string} params.reason - reason for the update, value from project-update-reasons.js.
     * @param {object} params.data - data associated with need for this "refetch".
     */
    // eslint-disable-next-line complexity, max-lines-per-function
    refetchUiState(
      { commit, dispatch, state, rootGetters, rootState },
      { reason, data, supressProcessingIndicator = false },
    ) {
      const projectId = state.project.id;
      const reloadFileData = () => Promise.all([
        dispatch('FETCH_PROJECT_FILE_INFO', { supressProcessingIndicator, projectId }),
        dispatch('FETCH_DOCUMENTS', { supressProcessingIndicator }),
      ]);

      if (reason === FILE_STATUS_UPDATED) {
        if (isProcessed({ file_status: data.status }) && !data.rulesOnly) {
          // Reload on full processing to get updated clause data.
          return reloadFileData();
        }
        commit('changeStatus', {
          selection: { fileIds: [data.fileId] },
          status: data.status,
        });
        // Fetch the updated overview counts.
        return dispatch('FETCH_PROJECT_FILE_INFO', { supressProcessingIndicator, projectId });
      }
      if (reason === FILE_DELETED) {
        dispatch('FETCH_PROJECT_FILE_INFO', { supressProcessingIndicator, projectId });
        return commit('MARK_FILES_DELETED', data);
      }
      if (reason === FILE_UPDATED) {
        const { reprocessed } = data;
        if (reprocessed) {
          const processed_count = state.file_info.processed_count - reprocessed.length;
          commit('SET_PROJECT_FILE_INFO', { ...state.file_info, processed_count });
          commit('socketChangeStatus', reprocessed);
          return;
        }
        return commit('UPDATE_DOCUMENT', data);
      }
      if (reason === FILE_UPLOADED) {
        // Refetch file data when a new file is uploaded:
        return reloadFileData();
      }
      if (reason === LAYOUTS_DELETED) {
        return commit('layout/setUserLayout', {});
      }
      if (reason === PROJECT_FIELD_UPDATE) {
        return commit('UPDATE_DOCUMENT_PROJECT_FIELD', data);
      }
      if (reason === PROJECT_FIELD_CREATED) {
        commit('ADD_PROJECT_FIELD', data);
        commit('addEmptyProjectFieldData', data);
        return;
      }
      if (reason === PROJECT_FIELD_DELETED) {
        commit('deleteField', data.project_field_id);
        commit('REMOVE_DOCUMENTS_PROJECT_FIELD', data.project_field_id);
        return;
      }
      if (reason === REVIEWERS_UPDATED) {
        const { fileIds, added, removed } = data;
        const myReviews = ({ user_id }) => user_id === rootState.auth.loggedInUser.userId;
        const addedReviewers = fileIds.flatMap(file_id => added.map(user_id => {
          const { first_name, last_name } = rootGetters['users/getUserById'](user_id);
          return { user_id, file_id, review_status: PENDING, first_name, last_name };
        }));

        const removedReviewers = fileIds.flatMap(file_id => removed.map(user_id => ({ file_id, user_id })));

        const myAssignedChange = addedReviewers.filter(myReviews).length - removedReviewers.filter(myReviews).length;
        const assigned_to_me = state.file_info.assigned_to_me + myAssignedChange;
        commit('SET_PROJECT_FILE_INFO', { ...state.file_info, assigned_to_me });

        return commit('UPDATE_REVIEWERS', { addedReviewers, removedReviewers });
      }
      if (reason === REVIEW_UPDATE) {
        const { reviewStatus, userId, fileIds } = data;
        const statusCommitMap = {
          [IN_SCOPE]: ['MARK_FILES_IN_SCOPE', { fileIds }],
          [OUT_OF_SCOPE]: ['MARK_FILES_OUT_OF_SCOPE', { fileIds, userId }],
          [REVIEWED]: ['MARK_FILES_REVIEWED', { fileIds, userId }],
          [PENDING]: ['MARK_FILES_UNREVIEWED', { fileIds, userId }],
        };
        commit(...statusCommitMap[reviewStatus]);
        // Reload file data to include reviews for the case where a document was marked as reviewed
        // and the user was not yet assigned as a reviewer.
        return reloadFileData();
      }
      if (reason === TEAM_UPDATED) {
        return commit('SET_COLLABORATORS', data);
      }
      if (reason === TAGS_MODIFIED) {
        const { newProjectTags, fileIds, unapplied, applied } = data;

        if (newProjectTags?.length) {
          commit('setTags', [...state.tags, ...newProjectTags]);
        }

        return fileIds.forEach(fileId => {
          unapplied?.forEach(removedTagId => commit('removeFileTag', { tagId: removedTagId, fileId }));
          applied?.forEach(addedTagId => commit('addFileTag', { tagId: addedTagId, fileId }));
        });
      }
      // eslint-disable-next-line no-console
      console.warn(`you should probably be more precise and not reload all pv data,
        give me a valid reason why we are updating. Reason provided: ${reason}`);
      return dispatch('RELOAD_PV_DATA');
    },

    /**
     * Adds tag to the project.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - commit function.
     * @param {object} params - params used to add a tag.
     * @param {number} params.projectId - id of a project to which the tag is added.
     * @param {...Object} params.data - data of the tag being added.
     */
    async addTag({ commit }, { projectId, ...data }) {
      const response = await axios.post(`/api/projects/${projectId}/tags`, { data });
      commit('addTag', response.data.data);
    },

    /**
     * DELETE  project field
     * @param {number} id - id of the project field to delete.
     */
    async deleteProjectField(_, id) {
      await axios.delete(`/api/projectFields/${id}`);
    },

    /**
     * Deletes tag (and all file relationships with this tag) from a project.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - commit function.
     * @param {object} context.state - module state.
     * @param {number} id - ID of the tag to remove.
     */
    async deleteTag({ commit, state }, id) {
      await axios.delete(`/api/projects/${state.project.id}/tags/${id}`);
      commit('deleteTag', id);
    },

    /**
     * Fetches all tags for a given project.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - commit function.
     * @param {number} projectId - ID of the project for which we want to fetch tags.
     */
    fetchProjectTags: throttle(async ({ commit }, projectId) => {
      const response = await axios.get(`/api/projects/${projectId}/tags`);
      const data = response.data.data;
      commit('setTags', data);
    }, THROTTLE_TIME),

    /**
     * PATCH project field.
     * @param {Function} commit - function calling vuex state mutations
     * @param {number} id - id of the project field to update.
     * @param {string} name - new name for the project field.
     * @param {{ add: string[], remove: string[] }} options - options to add and remove.
     * @param {boolean} multi - whether this field can have multiple values checked.
     * @returns {Promise}
     */
    async updateProjectField({ commit }, { id, name, options, multi }) {
      const fieldData = {
        name,
        options,
      };
      if (multi !== undefined) {
        fieldData.field_type = { multi };
      }
      const params = { data: fieldData };
      const response = await axios.patch(`/api/projectFields/${id}`, params);
      commit('updateProjectField', response.data.data);
    },
    /**
     * Patch a project field pick list option.
     *
     * @param {import('vuex').ActionContext} context - Vuex action context.
     * @param {object} params - The option params.
     * @param {number} params.fieldId - The project field id.
     * @param {number} params.id - The option id.
     * @param {string} params.name - The updated name.
     * @param {number} params.order - The updated order.
     *
     * @return {Promise<object>}
     */
    async updateProjectFieldItem(context, { fieldId, id, name, order }) {
      const itemData = { name, order };
      const response = await axios.patch(`/api/projectFields/${fieldId}/options/${id}`, { data: itemData });
      return response.data.data;
    },
    /**
     * PATCH project tag.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - commit function.
     * @param {object} context.state - module state.
     * @param {object} params - params used to add a tag.
     * @param {number} params.id - id of a the tag to patch.
     * @param {...Object} params.data - patch data.
     */
    async updateTag({ commit, state }, { id, ...data }) {
      await axios.patch(`/api/projects/${state.project.id}/tags/${id}`, { data });
      commit('updateTag', { id, ...data });
    },

    /**
     * Fetch project data & establish websocket connections.
     *
     * @param {function} dispatch - Dispatch actions.
     * @param {function} commit - Commit state.
     * @param {object} state - Vuex state of this module.
     * @param {number} projectId - The ID of the project to initialize.
     * @param {number} userId - The ID of the user viewing the project.
     */
    async initProjectPage({ dispatch, commit, state }, { projectId }) {
      const socket = state.socket;
      await Promise.all([
        dispatch('FETCH_PROJECT', { projectId }),
        dispatch('FETCH_PROJECT_FIELDS', projectId),
        dispatch('FETCH_PROJECT_FILE_INFO', { projectId }),
        dispatch('permissions/fetchProjectPermissions', projectId),
        dispatch('layout/fetchUserLayout'),
        dispatch('layout/fetchProjectLayoutTemplate'),
        dispatch('FETCH_CLAUSES'),
        dispatch('FETCH_TEAM_DATA'),
        dispatch('FETCH_EXAMPLE_COUNT_DATA', projectId),
        dispatch('FETCH_DOCUMENTS', projectId),
        dispatch('fetchProjectTags', projectId),
      ]);

      socket.on('reconnect', () => dispatch('onSocketReconnect'));

      socket.on('projectUpdated', data => {
        if (data.uuid !== uuid) {
          dispatch('refetchUiState', { ...data, supressProcessingIndicator: true });
        }
      });

      socket.on('upload-event', data => {
        if (data.type === 'progress') {
          commit(data.isInProgress ? 'SHOW_DOCUMENTS_UPLOADER' : 'HIDE_DOCUMENTS_UPLOADER');
          commit('setIsUploading', data.isInProgress);
          commit('setIsUnzipping', data.isInProgress);
        }
        // File upload store/unzip is complete?  Close dialogue
        else if (data.type === 'complete') {
          dispatch('finishUpload', { projectId, uploadedInfo: data.uploadedInfo });
        }
        else if (data.type === 'start') {
          commit('setIsUnzipping', true);
        }
        else if (data.type === 'error') {
          Vue.diligenToast.showError(data.message);
          dispatch('finishUpload', { projectId, cancelled: true });
        }
      });

      socket.on('deleteTag', tagId => commit('deleteTag', tagId));
    },

    /**
     * Initialize socket connection for this project view.
     * @param {import('vuex').ActionContext} context
     */
    connectPvSocket({ commit, getters }) {
      const projectId = getters.project_id;
      commit('setPvSocket', getSocket({ projectId }));
    },
    disconnectPvSocket({ state }) {
      state.socket.disconnect();
    },
    onSocketReconnect: withProcessingIndicator('socket-reconnect', ({ dispatch }) => dispatch('RELOAD_PV_DATA')),
    async fetchKeyList({ commit, getters }) {
      const { data } = await axios.get(`/api/projects/${getters.project_id}/keylist`);
      commit('setKeyList', data.data);
    },
    async FETCH_CLAUSES({ commit }) {
      const { data } = await axios.get('/api/clauses');
      commit('SET_CLAUSES', data.data);
    },
    FETCH_PROJECT: throttle(async ({ commit }, { projectId, withRelated }) => {
      const response = await axios.get(`/api/projects/${projectId}`, { params: withRelated });
      const { tags, projectFields, ...project } = response.data.data;
      if (tags?.length) {
        commit('setTags', tags);
      }
      if (projectFields?.length) {
        commit('SET_PROJECT_FIELDS', projectFields.sort(nameCompare));
      }
      commit('SET_PROJECT', project);
    }, THROTTLE_TIME),
    FETCH_PROJECT_FIELDS: throttle(async ({ commit }, projectId) => {
      const response = await axios.get(`/api/projects/${projectId}/projectFields`);
      const data = response.data.data;
      commit('SET_PROJECT_FIELDS', data.sort(nameCompare));
    }, THROTTLE_TIME),
    FETCH_PROJECT_FILE_INFO: throttle(withProcessingIndicator('file-info', async ({ commit }, { projectId }) => {
      const { data } = await axios.get(`/api/projects/${projectId}/statusOverview`);
      commit('SET_PROJECT_FILE_INFO', data.data);
    }), THROTTLE_TIME),
    async FETCH_EXAMPLE_COUNT_DATA({ commit }, projectId) {
      const { data } = await axios.get(`/api/projects/${projectId}/exampleCount`);
      const totalCount = data.data.reduce((total, { count }) => total + count, 0);
      commit('setExampleCount', totalCount);
      commit('setClauseExampleCounts', data.data);
    },
    async UPDATE_PROJECT({ commit }, { id, properties }) {
      await axios.patch(`/api/projects/${id}`, properties);
      commit('UPDATE_PROJECT', properties);
    },
    async RELOAD_PV_DATA({ dispatch, getters, state }, { projectId, supressProcessingIndicator = false } = {}) {
      // Don't reload PV when uploading, getting document list is slow
      if (!state.setIsUploading) {
        const reloadedProjectId = projectId || getters.project_id;
        await Promise.all([
          dispatch('FETCH_DOCUMENTS', { projectId: reloadedProjectId, supressProcessingIndicator }),
          dispatch('FETCH_PROJECT', { projectId: reloadedProjectId }),
          dispatch('FETCH_PROJECT_FILE_INFO', { projectId: reloadedProjectId, supressProcessingIndicator }),
          dispatch('fetchProjectTags', reloadedProjectId),
        ]);
      }
    },
    startUpload({ commit }) {
      commit('setIsUploading', true);
    },
    async finishUpload({ dispatch, commit }, { projectId, uploadedInfo, cancelled = false }) {
      commit('HIDE_DOCUMENTS_UPLOADER');
      commit('setIsUploading', false);
      commit('setIsUnzipping', false);
      if (!cancelled) {
        // Treat as successful if we have added some unique files or we have no uploadedInfo data and just can't tell.
        const treatAsSuccessful = !uploadedInfo || uploadedInfo.uniques;
        const toastMessage = getFinishUploadMessage(uploadedInfo);
        Vue.diligenToast[treatAsSuccessful ? 'showSuccess' : 'showError'](toastMessage);
        if (treatAsSuccessful) {
          await dispatch('RELOAD_PV_DATA', { projectId, supressProcessingIndicator: true });
        }
      }
    },
    // eslint-disable-next-line complexity
    async downloadProjectTemplate({ state, rootState, rootGetters, dispatch }, projectId) {
      const fetchRequests = [axios.get(`/api/projects/${projectId}/templateJson`)];
      const { layout } = rootState.project;

      // Fetch data that might not be present in state, but is required for the template.
      if (!layout.layoutTemplate || layout.layoutTemplate.projectId !== projectId) {
        fetchRequests.push(dispatch('layout/fetchProjectLayoutTemplate', projectId));
      }
      if (!state.clauses.length) {
        fetchRequests.push(dispatch('FETCH_CLAUSES'));
      }

      // Destructure the first request item for the template data.
      const [{ data }] = await Promise.all(fetchRequests);

      // Get the now loaded layout items.
      const { layoutTemplate, userProjectLayout } = layout;
      const stateLayouts = get(layoutTemplate, 'document_page', {});

      const namesToKeys = entry => (
        data.data.project_data.fields.find(field => field.name === entry)?.project_field_key
        || state.clauses.find(clause => clause.name === entry)?.ml_key
        || entry
      );

      const reportDefault = get(layoutTemplate, 'reports')
        || reportUtils.getProjectReportItems(projectId)?.map(namesToKeys)
        || {};

      const reports = (Array.isArray(reportDefault) && reportDefault.length)
        ? { 'Template Report': reportDefault }
        : reportDefault;

      // Remove values from a property in the layouts_data that no longer exist at the project level.
      const fieldInTemplate = val => data.data.project_data.fields.find(field => field.project_field_key === val);
      const filterValidFields = (layoutProperty, additionalCheckFn) => Object
        .entries(layoutProperty)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .reduce((agg, [name, values]) => {
          const filteredValues = values.filter(value => fieldInTemplate(value) || additionalCheckFn?.(value));
          return filteredValues.length ? { ...agg, [name]: filteredValues } : agg;
        }, {});

      let pvLayout = rootGetters['project/layout/pvLayout'] || userProjectLayout;
      // Convert any field names in pvLayout to keys for template
      pvLayout = pvLayout.map(entry =>
        state.clauses.find(clause => clause.name === entry)?.ml_key
        || data.data.project_data.fields.find(field => field.name === entry)?.project_field_key
        || entry);

      const clauseKeys = map(state.clauses, 'ml_key');
      const filteredPv = pvLayout.filter(value => clauseKeys.includes(value)
        || fieldInTemplate(value)
        || Object.values(NON_DATA_FIELD_NAMES).includes(value));

      const fileData = {
        project_data: data.data.project_data,
        layouts: {
          reports: filterValidFields(
            reports,
            value => clauseKeys.includes(value) || AVAILABLE_FEATURES.includes(value),
          ),
          project_page: filteredPv,
          document_page: filterValidFields(stateLayouts, value => clauseKeys.includes(value)),
        },
      };

      await downloadResponse(fileData, `project-${projectId}-template.json`);
    },

    async getChartData({ state, getters }, { fieldName, fieldValues, ...searchParams }) {
      const { id: fieldId } = state.project_fields.find(({ name }) => name === fieldName);
      const query = { ...searchParams, 'fieldValues[]': fieldValues };
      const { data } = await axios.get(
        `/api/projects/${getters.project_id}/projectFields/${fieldId}/barchart`,
        { params: query },
      );
      return data.data;
    },
  },
  modules: {
    collaboratorModule,
    reviewStatusModule,
    documentsModule,
    actionsIndicator,
    filtering: {
      namespaced: true,
      ...filteringModule,
    },
    layout: {
      namespaced: true,
      ...layoutModule,
    },
    table: {
      namespaced: true,
      ...tableModule,
    },
    permissions: {
      namespaced: true,
      ...permissionsModule,
    },
  },
};
