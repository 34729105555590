import axios from 'helpers/diligen-xhr-axios';

function transformPlanData(plan) {
  return {
    id: plan.id,
    name: plan.name,
    subheading: plan.metadata.plan_subheading,
    contracts: plan.metadata.plan_reviews,
    storage: parseInt(plan.metadata.plan_uploaded_bytes, 10),
    extras: plan.metadata.plan_extras ? JSON.parse(plan.metadata.plan_extras) : [],
    price: plan.amount / 100,
    currency: plan.currency.toUpperCase(),
    slot: plan.metadata.plan_slot,
    slot_index: plan.metadata.plan_slot_index,
    interval: plan.interval,
  };
}

export default {
  state: {
    availablePlans: {
      slot_1: [],
      slot_2: [],
      slot_3: [],
    },
    currentPlan: {},
  },
  getters: {
    planName({ currentPlan }) {
      return currentPlan?.name;
    },
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    setAvailablePlans(state, plans) {
      let availablePlans = {
        slot_1: [],
        slot_2: [],
        slot_3: [],
      };

      for (const plan of Object.values(plans)) {
        availablePlans[`slot_${plan.metadata.plan_slot}`].push(transformPlanData(plan));
      }

      // Sort plans in slots by index
      Object.keys(availablePlans).map(key => availablePlans[key].sort((a, b) => a.slot_index - b.slot_index));

      state.availablePlans = availablePlans;
    },
    setCurrentPlan(state, plan) {
      if (plan) {
        state.currentPlan = transformPlanData(plan);
      }
    },
  },
  /* eslint-enable */
  actions: {
    /**
     * Load and set all plans in store.
     *
     * @param {function} commit - Function to commit changes via mutators.
     */
    initPlans: async ({ commit }) => {
      const response = await axios.get('/api/plans');
      const data = response.data.data;
      commit('setAvailablePlans', data.available);
      commit('setCurrentPlan', data.available[data.current] ?? null);
    },
  },
};
