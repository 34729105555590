import jwt_decode from 'jwt-decode';

const JWT_KEY = 'jwt';

const getFromStorage = () => localStorage.getItem(JWT_KEY);

const utils = {
  getFromStorage,
  store: (jwtString) => localStorage.setItem(JWT_KEY, jwtString),
  clear: () => localStorage.removeItem(JWT_KEY),
  isValid: () => {
    try {
      const { exp } = jwt_decode(getFromStorage());
      return exp > (Date.now() / 1000);
    }
    catch (error) {
      return false;
    }
  },
};

export default utils;
