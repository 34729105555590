import axios from 'helpers/diligen-xhr-axios';

export default {
  state: {
    invoices: [],
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    setInvoices(state, invoices) {
      state.invoices = invoices;
    },
  },
  /* eslint-enable */
  actions: {
    /**
     * Load and set all invoices in store.
     *
     * @param {function} commit - Function to commit changes via mutators.
     */
    initInvoices: async ({ commit }) => {
      const { data } = await axios.get('/api/list-invoices');
      commit('setInvoices', data.data.invoices);
    },
  },
};
