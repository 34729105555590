import axios from 'helpers/diligen-xhr-axios';
import { downloadResponse } from 'helpers/dom-utils';
import { nameCompare } from 'helpers/sort-utils';

export default {
  state: {
    projects: [],
    templates: [],
    preferPlainTextView: false,
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    REMOVE_PROJECT(state, removedProject) {
      state.projects = state.projects.filter(project => project.id !== removedProject.id);
    },
    SET_PROJECTS(state, projects) {
      state.projects = projects;
    },
    UPDATE_PROJECT_SETTINGS(state, { id, properties: { name, description }, stateCollaborators }) {
      let modifiedProject = state.projects.find(project => project.id === id);
      modifiedProject.description = description;
      modifiedProject.name = name;
      modifiedProject.project_team = stateCollaborators;
    },
    setTemplates(state, templates) {
      state.templates = templates;
    },
    /**
     * Adds a new template to state.
     * @param {object} state - vuex state to append to.
     * @param {object} template - template to add.
     */
    addTemplate(state, template) {
      state.templates.push(template);
    },
    /**
     * Updates a template.
     * @param {object} state - vuex state to mutate.
     * @param {object} options
     * @param {number} options.id - id of the template to update.
     * @param {...Object} options.data - data to patch.
     */
    updateTemplate(state, { id, ...data }) {
      const updatedTemplate = state.templates.find(template => template.id === id);
      Object.assign(updatedTemplate, data);
    },
    /**
     * Removes a template from state.
     * @param {object} state - vuex state to change.
     * @param {number} id - id of template to remove.
     */
    deleteTemplate(state, id) {
      state.templates = state.templates.filter(template => template.id !== id);
    },
    /**
     * Set the user preference for the plain text PDF view across all projects.
     * Saved automatically when plain text is selected in SDV.
     * @param {object} state - The module state.
     * @param {boolean} value - If plain text view should be preferred.
     */
    setPlainTextPreference(state, value) {
      state.preferPlainTextView = value;
    },
  },
  /* eslint-enable no-param-reassign */
  actions: {
    /**
     * Load and set all projects in store.
     *
     * @param {object} context - The Vuex action context.
     * @param {function} context.commit - Function to commit changes via mutators.
     */
    INIT_PROJECTS: async ({ commit }) => {
      const response = await axios.get('/api/projects');
      commit('SET_PROJECTS', response.data.data.projects);
    },

    async UPDATE_PROJECT_SETTINGS({ commit, rootGetters }, { id, properties, collaborators }) {
      await axios.patch(`/api/projects/${id}`, properties);
      await axios.post(`/api/projects/${id}/team`, { project_team: collaborators });
      const stateCollaborators = collaborators.map(({ user_id, project_role }) => {
        const { email, name, first_name, last_name } = rootGetters['users/getUserById'](user_id);
        return {
          user_id,
          email,
          project_role,
          name,
          first_name,
          last_name,
        };
      });
      commit('UPDATE_PROJECT_SETTINGS', { id, properties, stateCollaborators });
    },

    fetchTemplates: async ({ commit }) => {
      const response = await axios.get('/api/templates');
      commit('setTemplates', response.data.data.templates.sort(nameCompare));
    },

    /**
     * Add a template.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - commit function.
     * @param {object} data - params used to add a template.
     */
    async addTemplate({ commit }, data) {
      const response = await axios.post('/api/templates', data);
      commit('addTemplate', response.data.data);
    },

    /**
     * Update a template.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - commit function.
     * @param {object} params - params used to update the template.
     * @param {number} params.id - id of a the template to patch.
     * @param {...Object} params.data - patch data.
     */
    async updateTemplate({ commit }, { id, ...data }) {
      await axios.patch(`/api/templates/${id}`, data);
      const { templateData, ...updatedTemplate } = data;
      commit('updateTemplate', { id, ...updatedTemplate });
    },

    /**
     * Delete a template.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - commit function.
     * @param {number} id - ID of the template to remove.
     */
    async deleteTemplate({ commit }, id) {
      await axios.delete(`/api/templates/${id}`);
      commit('deleteTemplate', id);
    },

    /**
     * Download a template file.
     * @param {object} context - Vuex action context.
     * @param {number} id - The template id to fetch.
     */
    async downloadTemplate(context, id) {
      const response = await axios.get(`/api/templates/${id}`);
      const fileData = {
        project_data: response.data.data.project_data,
        layouts: response.data.data.layouts,
      };
      await downloadResponse(fileData, `${response.data.data.name}.json`);
    },
  },
};
