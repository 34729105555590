<template>
  <div class="content">
    <PrimaryHeader>
      <AlertSvg class="icon" />Error - {{ errorTitle }}
    </PrimaryHeader>
    <div class="diligen-card" v-html="errorText" />
    <div class="reach-out">
      If you continue to get this message, please reach out to us at
      <a href="mailto:help@diligen.com">help@diligen.com</a>.
    </div>
  </div>
</template>

<script>
import PrimaryHeader from 'components/route-level/PrimaryHeader.vue';
import AlertSvg from 'svg/Alert.vue';

export default {
  components: {
    AlertSvg,
    PrimaryHeader,
  },

  props: {
    errorTitle: {
      type: String,
      required: true,
    },
    errorText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

:deep(.heading-1) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin: 0 60px;
}

.diligen-card {
  max-width: 500px;
  margin: 40px auto;
  padding: 20px;
}

.icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.reach-out {
  text-align: center;
  margin-top: 40px;
}
</style>
