<template>
  <!-- eslint-disable max-len, vue/html-indent, vue/html-closing-bracket-spacing -->
  <svg class="svg-component" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
    <path id="a" d="M12 11.76a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-1.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zm.027 11.975c-4.314 0-7.067-.632-8.382-1.946-.55-.493-.819-1.009-.763-1.538.448-4.253 4.536-7.736 9.081-7.766h.056c4.56.03 8.65 3.51 9.099 7.766.055.525-.21 1.043-.73 1.508-1.34 1.335-4.066 1.97-8.36 1.976zm-7.505-3.432c.073.092.146.171.228.245.978.977 3.474 1.502 7.276 1.502 3.787-.004 6.254-.533 7.252-1.528.065-.059.132-.132.193-.21-.408-3.334-3.77-6.118-7.445-6.142h-.047c-3.674.024-7.027 2.799-7.457 6.133z"/>
  </svg>
</template>

<script>
export default {
  name: 'ProfileSvg',
};
</script>
