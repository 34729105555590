import ReviewsHeader from 'components/project/documents/document/reviews/ReviewsHeader.vue';

import {
  SPECIAL_DATA_FIELD_NAMES,
  SPECIAL_DOCUMENT_FIELD_NAMES,
} from 'shared/constants/document-fields';

const SPECIAL_CLAUSE_FIELDS = [
  {
    name: SPECIAL_DATA_FIELD_NAMES.CONTRACT_DATE,
    component: 'ContractDate',
  },
  {
    name: SPECIAL_DATA_FIELD_NAMES.CONTRACT_NAME,
    component: 'MlValueSingular',
  },
  {
    name: SPECIAL_DATA_FIELD_NAMES.GOVERNING_LAW_PLACES,
    component: 'MlValueList',
  },
  {
    name: SPECIAL_DATA_FIELD_NAMES.CONTRACT_PARTIES,
    component: 'DocumentParties',
  },
  {
    name: SPECIAL_DATA_FIELD_NAMES.DOCUMENT_TYPE,
    component: 'DocumentType',
  },
];

const SPECIAL_OTHER_FIELDS = [
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.STATUS,
    component: 'PlainTextField',
    getter: 'statusToText',
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.ADDED_ON,
    component: 'AddedOn',
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.DUPLICATES,
    component: 'DuplicatesField',
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.EXECUTION,
    component: 'PlainTextField',
    getter: 'executionLabel',
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.NUMBER_OF_PAGES,
    component: 'PlainTextField',
    getter: 'numberOfPages',
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.FILE,
    component: 'FileName',
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.DOCUMENT_NUMBER,
    component: 'PlainTextField',
    getter: 'file_id',
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.FOLDER,
    component: 'PlainTextField',
    getter: 'folderPath',
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.REVIEW,
    component: 'Reviews',
    headerComponent: ReviewsHeader,
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.TAGS,
    component: 'FileTags',
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.LAST_REVIEW_TIME,
    component: 'PlainTextField',
    getter: 'lastReviewTime',
  },
  {
    name: SPECIAL_DOCUMENT_FIELD_NAMES.HAS_OCR,
    component: 'PlainTextField',
    getter: 'hasOcr',
    booleanValue: true,
  },
];

const SPECIAL_DOCUMENT_FIELDS = [
  ...SPECIAL_CLAUSE_FIELDS,
  ...SPECIAL_OTHER_FIELDS,
];

function clauseFieldFactory(clause) {
  return {
    ...clause,
    component: 'ClauseField',
  };
}

const findSpecialClauseField = (clause) => SPECIAL_CLAUSE_FIELDS.find(({ name }) => name === clause.name);

const isSpecialClause = (clause) => !!findSpecialClauseField(clause);

function specialClauseFieldFactory(clause) {
  return {
    ...clause,
    ...findSpecialClauseField(clause),
  };
}

function projectFieldFactory({ name, project_field_key }) {
  return {
    name,
    project_field_key,
    component: 'ProjectField',
  };
}

function isClauseField(field) {
  return field.component === 'ClauseField';
}

function isDateBasedField(field) {
  return field.name === SPECIAL_DOCUMENT_FIELD_NAMES.CONTRACT_DATE;
}

function isProjectField(field) {
  return field.component === 'ProjectField';
}

function getProjectFieldData({ projectFields, fileId, projectFieldName }) {
  const projectColumn = projectFields.find((x) => x.name === projectFieldName);
  const projectColumnData = projectColumn.data.find((x) => x.file_id === fileId);
  return projectColumnData && projectColumnData.data;
}

export {
  projectFieldFactory,
  clauseFieldFactory,
  specialClauseFieldFactory,
  getProjectFieldData,
  isProjectField,
  isClauseField,
  isDateBasedField,
  isSpecialClause,
  SPECIAL_DOCUMENT_FIELDS,
  SPECIAL_OTHER_FIELDS,
};
