import superagent from 'superagent';
import Vue from 'vue';
import uuid from './client-uuid';
import jwtUtils from './jwt-utils';

// "New Version" Toast should be shown only once per "page refresh".
let newVersionToastShown = false;

/**
 * Handle the request error based on the status.
 *
 * @param {object} error - The error object.
 * @param {number} error.status - The error status.
 * @param {object} error.response - The response object.
 * @returns {boolean} - true if error was handled.
 */
// eslint-disable-next-line complexity
const handleErrorStatus = (error) => {
  const { status, response } = error;
  if (status === 401) {
    const currentRelativeLocation = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    if (window.location.pathname === '/login') {
      return false;
    }
    window.location.replace(`/login?redirect_to=${currentRelativeLocation}`);
  }
  else if (status === 402) {
    const body = response.body;
    if (body && body.location) {
      window.location.replace(body.location);
    }
  }
  else if (status === 403) {
    Vue.diligenToast.showApiError(
      error,
      'You do not have access to this resource. Contact an Account Owner to request access.',
      { isSingleton: true },
    );
  }
  else if (status === 503) {
    Vue.diligenToast.showApiError(
      error,
      'Diligen is currently undergoing maintenance. Apologies for the inconvenience.',
      { isSingleton: true },
    );
  }
  else {
    return false;
  }
  return true;
};

const agent = superagent.agent();

// Middleware to handle URL prefixing, JWT, and UUID setting
agent.use((request) => {
  // Prefix any absolute URLs
  if (request.url[0] === '/') {
    request.url = `${window.location.protocol}//${window.location.host}${request.url}`;
  }

  // Set JWT in headers if available
  const jwt = jwtUtils.getFromStorage();
  if (jwt) {
    request.set({ Authorization: `Bearer ${jwt}` });
  }
  // Sets same uuid for every request. This uuid varies from tab to tab in the web browser even for same jwt session.
  // It will change when you refresh your browser (tab).
  // It is useful when the server wants to send a socket notification with information
  // about the client that caused the notification to happen (by sending an xhr request).
  // Then on the client side we can compare the "tab" uuid with uuid comming from the server.
  // If they match we can decide to ignore the notification - since we caused the whole action we might not need it.
  request.set({ uuid });

  request.on('response', ({ headers }) => {
    const releaseId = headers?.['application-release-id'] || false;
    // If a new version has been released since when this javascript was loaded, warn user
    if (!newVersionToastShown && releaseId && releaseId !== process.env.VUE_APP_RELEASE) {
      const message = 'A new version of Diligen has been released. Please save your work, then reload the page to get the latest version.'; // eslint-disable-line max-len
      const options = (process.env.VUE_APP_DILIGEN_ENV === 'development')
        ? { delay: 1000 }
        : { sticky: true };
      Vue.diligenToast.showInfo(message, { ...options, isSingleton: true });
      newVersionToastShown = true;
    }
  });

  request.on('error', (error) => {
    const handled = handleErrorStatus(error);
    // Mark this error as handled to prevent duplicated error toasts for already handled 403 and 503 responses.
    // eslint-disable-next-line no-param-reassign
    error.handledByDiligenXhr = handled;
  });
});

export default agent;
