import Vue from 'vue';
import { withProcessingIndicator } from './actions-in-progress';
import request from 'helpers/diligen-xhr';
import { IN_SCOPE, OUT_OF_SCOPE, REVIEWED, PENDING } from 'shared/constants/review-status-codes';

export default {
  actions: {
    EDIT_REVIEW_STATUS: withProcessingIndicator('review-action', async ({ commit }, { url, ...options }) => {
      try {
        await request.post(url).send(options);
      }
      catch (error) {
        if (!error.handledByDiligenXhr) {
          Vue.diligenToast.showError('Update failed. Please try again.');
          throw error;
        }
      }
    }),
    MARK_IN_SCOPE({ dispatch }, options) {
      return dispatch('EDIT_REVIEW_STATUS', {
        ...options,
        url: '/api/documents/markInScope',
        reviewStatus: IN_SCOPE,
      });
    },
    MARK_OUT_OF_SCOPE({ dispatch }, options) {
      return dispatch('EDIT_REVIEW_STATUS', {
        ...options,
        url: '/api/documents/markOutOfScope',
        reviewStatus: OUT_OF_SCOPE,
      });
    },
    MARK_REVIEWED({ dispatch }, options) {
      return dispatch('EDIT_REVIEW_STATUS', {
        ...options,
        url: '/api/documents/markReviewed',
        reviewStatus: REVIEWED,
      });
    },
    UNMARK_REVIEWED({ dispatch }, options) {
      return dispatch('EDIT_REVIEW_STATUS', {
        ...options,
        url: '/api/documents/unmarkReviewed',
        reviewStatus: PENDING,
      });
    },
  },
};
