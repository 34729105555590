import qs from 'qs';
import Vue from 'vue';
import Router from 'vue-router';
import multiguard from 'vue-router-multiguard';
import axios from 'helpers/diligen-xhr-axios';
import { setTabTitle } from 'helpers/dom-utils';
import jwtUtils from 'helpers/jwt-utils';
import parseQuery from 'helpers/parse-query/parse-query';
import { TOKEN_EXPIRED } from 'shared/constants/error-codes';
import store from 'store/store';

Vue.use(Router);

const redirectAuthenticated = async (to, from, next) => {
  await store.dispatch('auth/initLoggedInUser'); // await to see if the user is logged in
  if (!store.getters['auth/isLoggedInLoaded']) {
    next(); // if user is not logged in we proceed
  }
  else {
    // if user is logged in we redirect to /projects page
    window.location.href = '/projects';
  }
};

const validateResetToken = async (to, from, next) => {
  try {
    await axios.get('/api/users/validateToken', {
      params: { token: to.params.token },
    });
    next();
  }
  catch (error) {
    if (error.response && error.response.status === 401) {
      next({ path: '/forget', query: { token_expired: 1 } });
    }
    else {
      store.commit('setPageError', {
        errorTitle: 'Could Not Reset Password',
        errorText: 'We could not reset your password at this time.',
      });
    }
  }
};

const validateInviteToken = async (to, from, next) => {
  try {
    await axios.get('/api/users/validateToken', {
      params: { token: to.params.token },
    });
    next();
  }
  catch (error) {
    if (error.response && error.response.status === 401) {
      if (error.response?.data?.errors?.[0]?.code === TOKEN_EXPIRED) {
        store.commit('setPageError', {
          errorTitle: 'Invite Has Expired',
          errorText: 'Please contact an existing Account Owner to request a new invitation. The current invite has expired.', // eslint-disable-line max-len
        });
      }
      else {
        next({ path: '/login' });
      }
    }
    else {
      store.commit('setPageError', {
        errorTitle: 'Could Not Process Invite',
        errorText: 'We could not process your invite at this time.',
      });
    }
  }
};

const postEmailChange = async (to, from, next) => {
  try {
    await axios.post('/api/users/changeEmail', {
      token: to.params.token,
      email: to.query.email,
    });
    next();
  }
  catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle the 401 status specifically
      store.commit('setPageError', {
        errorTitle: 'Could Not Change Email',
        errorText: 'Your request is time-limited and has expired.<br><br>To change your email address, return to your <a href="/profile">profile</a> and submit the change again.', // eslint-disable-line max-len
      });
    }
    else {
      // Handle other errors generically
      store.commit('setPageError', {
        errorTitle: 'Could Not Change Email',
        errorText: 'We could not change your email address at this time.',
      });
    }
  }
};

const publicRoutes = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
    beforeEnter: redirectAuthenticated,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/forget',
    component: () => import(/* webpackChunkName: "forget" */ './views/ForgetPasswordPage.vue'),
    beforeEnter: redirectAuthenticated,
    meta: {
      title: 'Forgot Password',
    },
  },
  {
    path: '/reset/:token',
    component: () => import(/* webpackChunkName: "reset" */ './views/ResetPasswordPage.vue'),
    beforeEnter: multiguard([redirectAuthenticated, validateResetToken]),
    meta: {
      title: 'Reset Password',
    },
  },
  {
    path: '/invite/:token',
    component: () => import(/* webpackChunkName: "invite" */ './views/InviteFormPage.vue'),
    beforeEnter: multiguard([redirectAuthenticated, validateInviteToken]),
    meta: {
      title: 'Register',
    },
  },
  {
    path: '/change-email/:token',
    component: () => import(/* webpackChunkName: "change-email" */ './views/ChangeEmailPage.vue'),
    beforeEnter: postEmailChange,
    meta: {
      title: 'Update Email',
    },
  },
  {
    path: '/',
    redirect: '/projects',
  },
];

const routesForAuthenticated = [
  {
    path: '/billing',
    component: () => import(/* webpackChunkName: "billing" */ './views/BillingPage.vue'),
    meta: {
      title: 'Billing',
    },
  },
  {
    path: '/api-documentation',
    component: () => import(/* webpackChunkName: "diligenapi" */ './views/SwaggerPage.vue'),
    name: 'api-documentation',
    meta: {
      title: 'API Documentation',
    },
  },
  {
    path: '/api-documentation/changelog',
    component: () => import(/* webpackChunkName: "diligenapi" */ './views/Changelog.vue'),
    name: 'changelog',
    meta: {
      title: 'Changelog',
    },
  },
  {
    path: '/documents/:projectId(\\d+)/:fileId(\\d+)',
    component: () => import(/* webpackChunkName: "sdv" */ './views/SingleDocumentPage.vue'),
    name: 'sdv',
    meta: { hideNav: true },
  },
  {
    path: '/integrations',
    component: () => import(/* webpackChunkName: "integrations" */ './views/IntegrationsPage.vue'),
    meta: {
      title: 'Integrations',
    },
  },
  {
    path: '/integrations/confirm/clio',
    component: () => import(/* webpackChunkName: "integrations" */ './views/ClioCallback.vue'),
    meta: {
      title: 'Clio Integration',
    },
  },
  {
    path: '/integrations/confirm/nd',
    component: () => import(/* webpackChunkName: "integrations" */ './views/NdCallback.vue'),
    meta: {
      title: 'NetDocuments Integration',
    },
  },
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "profile" */ './views/ProfilePage.vue'),
    meta: {
      title: 'Profile',
    },
  },
  {
    path: '/projects/:projectId(\\d+)',
    component: () => import(/* webpackChunkName: "pv" */ './views/ProjectPage.vue'),
    name: 'pv',
  },
  {
    path: '/projects/:projectId(\\d+)/rules',
    component: () => import(/* webpackChunkName: "pv-rules" */ './views/ProjectRulesPage.vue'),
    name: 'rules',
    meta: {
      title: 'Rules',
    },
  },
  {
    path: '/projects/:projectId(\\d+)/keylist',
    component: () => import(/* webpackChunkName: "pv-rules" */ './views/ProjectKeysPage.vue'),
    name: 'keylist',
    meta: {
      title: 'Key List',
    },
  },
  {
    path: '/projects/:projectId(\\d+)/chart',
    component: () => import(/* webpackChunkName: "chart" */ './components/project/ProjectFieldChart.vue'),
    meta: {
      title: 'Project Field Chart',
    },
  },
  {
    path: '/projects',
    component: () => import(/* webpackChunkName: "po" */ './views/ProjectOverviewPage.vue'),
    meta: {
      title: 'Projects',
    },
  },
  {
    path: '/report/:id',
    component: () => import(/* webpackChunkName: "report" */ './views/ReportPage.vue'),
    meta: {
      title: 'Download Report',
    },
  },
  {
    path: '/subscription',
    component: () => import(/* webpackChunkName: "subscription" */ './views/SubscriptionPage.vue'),
    meta: {
      title: 'Manage Subscription',
    },
  },
  {
    path: '/subscription/cancel',
    component: () => import(/* webpackChunkName: "subscription" */ './views/SubscriptionCancelPage.vue'),
    meta: {
      title: 'Manage Subscription',
    },
  },
  {
    path: '/subscription/downgrade',
    component: () => import(/* webpackChunkName: "subscription" */ './views/SubscriptionDowngradeListPage.vue'),
    meta: {
      title: 'Manage Subscription',
    },
  },
  {
    path: '/subscription/downgrade/:plan_id',
    component: () => import(/* webpackChunkName: "subscription" */ './views/SubscriptionDowngradePage.vue'),
    meta: {
      title: 'Manage Subscription',
    },
  },
  {
    path: '/subscription/upgrade/:plan_id',
    component: () => import(/* webpackChunkName: "subscription" */ './views/SubscriptionUpgradePage.vue'),
    meta: {
      title: 'Manage Subscription',
    },
  },
  {
    path: '/team',
    component: () => import(/* webpackChunkName: "team" */ './views/TeamPage.vue'),
    meta: {
      title: 'Team',
    },
  },
  {
    path: '/training',
    component: () => import(/* webpackChunkName: "training" */ './views/TrainingPage.vue'),
    meta: {
      title: 'Clause Training',
    },
  },
  {
    path: '/training/test',
    name: 'testModel',
    component: () => import(/* webpackChunkName: "training" */ './views/TestModelPage.vue'),
    meta: {
      title: 'Model Testing',
    },
  },
  {
    path: '/training/:clause_id',
    name: 'trainModel',
    component: () => import(/* webpackChunkName: "training" */ './views/ExampleTrainingPage.vue'),
    meta: {
      title: 'Model Training',
    },
  },
];

const router = new Router({
  mode: 'history',
  routes: [
    ...publicRoutes.map(options => ({ ...options, meta: { ...options.meta, public: true } })),
    ...routesForAuthenticated,
    {
      path: '*',
      component: () => import(/* webpackChunkName: "404" */ './views/error/FourZeroFourPage.vue'),
    },
  ],
  // custom query resolver that parses nested objects, booleans, ints, floats and empty values (as nulls)
  parseQuery,
  stringifyQuery: query => {
    const result = qs.stringify(query);
    return result ? `?${result}` : '';
  },
  scrollBehavior(to, from, savedPosition) {
    const hashOnlyChange = from.path === to.path;
    if (hashOnlyChange) {
      // Keep position if we only change hash (for example when we jump to text using document overview links).
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  store.commit('setPageError', null); // reset page error as we navigate to a new route!
  // If this isn't a public route and the JWT is expired but not yet removed
  // then clear user and return to /login page before making any API requests.
  if (!to.meta.public && !jwtUtils.isValid()) {
    if (jwtUtils.getFromStorage() !== null) {
      store.commit('auth/setLoggedInUser', {});
      jwtUtils.clear();
      Vue.diligenToast.showInfo('Your session has expired. Please log in again.');
      next({ path: '/login', query: { redirect_to: to.fullPath }, replace: true });
      return;
    }
    // Else the jwt has already been cleared or doesn't exist yet - return to /login with no message.
    next({ path: '/login', replace: true });
    return;
  }
  store.dispatch('auth/initLoggedInUser', { ignore401: to.meta.public });

  // Update the route title when the metadata exists, otherwise this falls back to "Diligen".
  setTabTitle(to.meta?.title);

  next();
});

export default router;
