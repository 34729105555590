import axios from 'helpers/diligen-xhr-axios';
import { DELETE_PROJECT, MARK_OUT_OF_SCOPE } from 'shared/constants/permissions';

export default {
  state: {
    projectPermissions: [],
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    setProjectPermissions(state, projectPermissions) {
      state.projectPermissions = projectPermissions;
    },
  },
  /* eslint-enable no-param-reassign */
  getters: {
    permissionDeleteProject({ projectPermissions }) {
      return projectPermissions.includes(DELETE_PROJECT);
    },
    permissionMarkOutOfScope({ projectPermissions }) {
      return projectPermissions.includes(MARK_OUT_OF_SCOPE);
    },
  },
  actions: {
    async fetchProjectPermissions({ commit }, projectId) {
      const response = await axios.get(`/api/permissions/projects/${projectId}`);
      const projectPermissions = response.data.data.projectPermissions;
      commit('setProjectPermissions', projectPermissions);
    },
  },
};
