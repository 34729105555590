<template>
  <div :class="{ expanded: reviewsColumnExpanded }" class="reviews-header">
    <ArrowRightSvg class="expand-icon toggle-icon" @click="EXPAND_REVIEWS" />
    <ArrowLeftSvg class="collapse-icon toggle-icon" @click="COLLAPSE_REVIEWS" />
    <ReviewsSvg class="reviews-icon" />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import ReviewsSvg from 'svg/Reviewers.vue';
import ArrowLeftSvg from 'svg/arrows/ArrowLeft.vue';
import ArrowRightSvg from 'svg/arrows/ArrowRight.vue';

export default {
  components: {
    ArrowLeftSvg,
    ArrowRightSvg,
    ReviewsSvg,
  },
  computed: {
    ...mapGetters('project/table', [
      'reviewsColumnExpanded',
    ]),
  },
  methods: {
    ...mapActions('project/table', [
      'COLLAPSE_REVIEWS',
      'EXPAND_REVIEWS',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.reviews-header {
  width: 100%;
  height: 32px;
  padding-left: 5px;
  border-radius: 16.5px;
  border: solid 1px $color-reviews-header-border;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }

  .reviews-icon {
    display: block;
  }

  .toggle-icon {
    display: none;
    fill: $color-toggle-reviews-icon;
    cursor: pointer;
  }

  &:hover {
    &.expanded {
      .collapse-icon {
        display: block;
      }
    }

    &:not(.expanded) {
      .expand-icon {
        display: block;
      }
    }

    .reviews-icon {
      display: none;
    }
  }
}
</style>
