<template>
  <div v-click-outside="collapse">
    <div class="title-container" @click="collapsed ? expand() : collapse()">
      <slot :collapsed="collapsed" name="handle" />
    </div>
    <div v-show="!collapsed" :style="collapsableMenuPosition" class="menu diligen-dropdown-container">
      <!-- pass components in slot scope to provide better composability -->
      <slot :menu-item="$options.components.CollapsableMenuItem" :collapse="collapse" name="items" />
    </div>
  </div>
</template>

<script>
import { directive as ClickOutside } from 'v-click-outside';
import CollapsableMenuItem from './CollapsableMenuItem.vue';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CollapsableMenuItem,
  },
  directives: {
    ClickOutside,
  },
  props: {
    leftAligned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsed: true,
      collapsableMenuPosition: {},
    };
  },
  methods: {
    collapse() {
      this.collapsed = true;
      this.$emit('collapsed', this.collapsed);
    },
    expand() {
      this.collapsableMenuPosition = this.leftAligned
        ? { marginLeft: `${this.$el.clientWidth}px`, transform: 'translateX(-100%)' }
        : {};
      this.collapsed = false;
      this.$emit('collapsed', this.collapsed);
    },
  },
};
</script>

<style lang='scss' scoped>
  .title-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .menu {
    position: absolute;
    margin-top: 2px;
  }
</style>
