import { intersection } from 'lodash';
import Vue from 'vue';
import {
  projectFieldFactory,
  clauseFieldFactory,
  specialClauseFieldFactory,
  isSpecialClause,
  SPECIAL_DOCUMENT_FIELDS,
  SPECIAL_OTHER_FIELDS,
} from '@/object-decorators/document-field';
import groupClausesByCategory from 'helpers/group-clauses-by-category/group-clauses-by-category';
import { nameCompare } from 'helpers/sort-utils';
import { SPECIAL_DOCUMENT_FIELD_NAMES } from 'shared/constants/document-fields';

const {
  FILE,
  REVIEW,
  CONTRACT_DATE,
  CONTRACT_NAME,
} = SPECIAL_DOCUMENT_FIELD_NAMES;

const { localStorage } = window;

const REVIEW_COLUMN_EXPANDED = '82';
const REVIEW_COLUMN_COLLAPSED = '54';

const initialColumnWidths = () => ({
  [FILE]: '264',
  [REVIEW]: REVIEW_COLUMN_EXPANDED,
});

export default {
  state() {
    return {
      // columns that MUST be visible
      mandatoryColumns: [
        FILE,
        REVIEW,
      ],
      // columns shown when no custom ordering has been set by the user yet
      defaultColumnsOrder: [
        FILE,
        REVIEW,
        CONTRACT_NAME,
        CONTRACT_DATE,
        'Term of Contract',
        'Assignment Right',
      ],

      columnWidths: initialColumnWidths(),
    };
  },
  getters: {
    allColumnNames(state, { flatColumnValues }) {
      return flatColumnValues.map((x) => x.name);
    },
    specialClauseFields(state, getters, rootState) {
      return rootState.project.clauses.filter(isSpecialClause).map(specialClauseFieldFactory);
    },
    regularClauseFields(state, getters, rootState) {
      return rootState.project.clauses.filter((clause) => !isSpecialClause(clause)).map(clauseFieldFactory);
    },
    projectFields(state, getters, rootState) {
      return rootState.project.project_fields.map(projectFieldFactory);
    },
    reviewsColumnExpanded({ columnWidths }) {
      return columnWidths[REVIEW] === REVIEW_COLUMN_EXPANDED;
    },
    optionalColumns({ mandatoryColumns }, { regularClauseFields }) {
      return regularClauseFields.filter((clause) => !mandatoryColumns.includes(clause.name));
    },
    optionalFeatures({ mandatoryColumns }) {
      return SPECIAL_DOCUMENT_FIELDS.filter(({ name }) => !mandatoryColumns.includes(name)).sort(nameCompare);
    },
    columnGroups(state, { projectFields, optionalColumns, optionalFeatures }) {
      const clauseOptgroups = groupClausesByCategory(optionalColumns);

      return [
        {
          groupLabel: 'Features',
          groupOptions: optionalFeatures,
        },
        {
          groupLabel: 'Clauses',
          optgroups: clauseOptgroups,
        },
        {
          groupLabel: 'Project Fields',
          groupOptions: projectFields,
        },
      ];
    },
    flatColumnValues(state, { projectFields, regularClauseFields, specialClauseFields }) {
      return [...SPECIAL_OTHER_FIELDS, ...specialClauseFields, ...regularClauseFields, ...projectFields];
    },
    allOptionalColumnNames({ mandatoryColumns }, { allColumnNames }) {
      return allColumnNames.filter((name) => !mandatoryColumns.includes(name));
    },
    /**
     * get columns order stored in localStorage if there is any,
     * throw away no longer existing columns which names are stored in the system
     * @return {Array}
     */
    visibleColumnNames({ mandatoryColumns }, { allOptionalColumnNames, persistedColumnsOrder }) {
      // get rid off columns which are no longer in the system
      const savedColumns = intersection(persistedColumnsOrder, allOptionalColumnNames);

      // ensure mandatory columns come first!
      return [...mandatoryColumns, ...savedColumns];
    },
    visibleColumns(state, { visibleColumnNames, flatColumnValues }) {
      return visibleColumnNames.map((name) => flatColumnValues.find((x) => x.name === name));
    },
    persistColumnsKeys(state, getters, rootState, rootGetters) {
      return {
        project: `project-columns-${rootGetters['project/project_id']}`,
        global: 'project-columns-global',
      };
    },
    persistedColumnsOrder({ defaultColumnsOrder }, { persistColumnsKeys }, rootState, rootGetters) {
      const pvTemplateLayout = rootGetters['project/layout/pvLayout'];
      const layoutState = rootState.project.layout;
      const userDefinedLayout = layoutState.userProjectLayout.length ? layoutState.userProjectLayout : null;
      const globalDefaults = localStorage.getItem(persistColumnsKeys.global);
      try {
        return userDefinedLayout
          || pvTemplateLayout
          || JSON.parse(globalDefaults)
          || defaultColumnsOrder;
      }
      catch (e) { // in case of parsing error return DEFAULT_COLUMNS_ORDER
        return defaultColumnsOrder;
      }
    },
  },
  mutations: {
    /* eslint-disable no-param-reassign */
    SET_COLUMN_WIDTH(state, { name, width }) {
      Vue.set(state.columnWidths, name, width);
    },
    SET_INITIAL_COLUMN_WIDTHS(state) {
      state.columnWidths = initialColumnWidths();
    },
    /* eslint-enable no-param-reassign */
  },
  actions: {
    COLLAPSE_REVIEWS({ commit }) {
      commit('SET_COLUMN_WIDTH', { name: REVIEW, width: REVIEW_COLUMN_COLLAPSED });
    },
    EXPAND_REVIEWS({ commit }) {
      commit('SET_COLUMN_WIDTH', { name: REVIEW, width: REVIEW_COLUMN_EXPANDED });
    },
    STORE_GLOBAL_COLUMNS_ORDER({ getters }, newOrder) {
      const stringifiedOrder = JSON.stringify(newOrder);
      localStorage.setItem(getters.persistColumnsKeys.global, stringifiedOrder);
    },
  },
};
