<template>
  <a href="#" type="button"><CloseSvg class="close-icon" /></a>
</template>

<script>
import CloseSvg from 'svg/Close.vue';

export default {
  components: {
    CloseSvg,
  },
};
</script>

<style scoped lang="scss">
a {
  height: 24px;
  width: 24px;
}

.close-icon {
  fill: $color-icon-primary;
}
</style>
