import Vue from 'vue';
import jwtUtils from 'helpers/jwt-utils';

const FILE_NAME_PREFIX = 'filename*=UTF-8\'\'';

/**
 * Gets closest Element matching specified selectors.
 * Works for Element Nodes but also for Text Nodes.
 * @param {TextElement|NodeElement} node - node for which we search for closest ancestor element.
 * @param {DOMString} selectors -  DOMString containing a selector list such as ".line-component".
 * @returns {Element} which is the closest ancestor of the selected element. It may be null.
 */
const closest = (node, selectors) => {
  if (node.closest) {
    return node.closest(selectors);
  }
  return node.parentNode.closest(selectors);
};

/**
 * Parse a json blob into an object.
 * @param {Blob} blob - The json content as a Blob.
 * @return {Promise<Record<string, any>>} - Data object.
 */
const jsonBlobToObject = blob => new Promise(resolve => {
  const reader = new FileReader();
  reader.onload = () => resolve(JSON.parse(reader.result));
  reader.readAsText(blob);
});

/**
 * Save the supplied data as a file.
 * @param {any} data - The data to download as the provided content-type.
 * @param {string} fileName - The full name of the file (with extension).
 * @param {string} [contentType] - The content type of the file. Defaults to text/json.
 */
const downloadResponse = async (data, fileName, contentType = 'text/json') => {
  let fileData = data;
  if (contentType.includes('json')) {
    if (fileData instanceof Blob) {
      fileData = await jsonBlobToObject(fileData);
    }
    fileData = JSON.stringify(fileData, null, 2);
  }

  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(new Blob([fileData], { type: contentType }));
  downloadLink.download = fileName;
  downloadLink.click();
  URL.revokeObjectURL(downloadLink.href);
  downloadLink.remove();
};

const downloadResponseFrom = async axiosPromise => {
  try {
    const response = await axiosPromise;
    const contentDisposition = response.headers['content-disposition'];
    const fileNameStart = contentDisposition.indexOf(FILE_NAME_PREFIX) + FILE_NAME_PREFIX.length;
    const fileName = decodeURIComponent(contentDisposition.substring(fileNameStart, contentDisposition.length));
    return downloadResponse(response.data, fileName, response.headers['content-type']);
  }
  catch (error) {
    if (error.response && error.response.data) {
      error.response.data = await jsonBlobToObject(error.response.data);
    }
    Vue.diligenToast.showApiError(error);
  }
};

/**
 * Force the download to be managed in the browser by using a hidden form element to submit the request.
 *
 * @param {string} url - A url that provides the file data to download.
 */
const forceBrowserDownload = url => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = url;
  form.innerHTML = `<input type="hidden" name="access_token" value="${jwtUtils.getFromStorage()}">`;

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

/**
 * Encode blob data as base64.
 * @param {Blob} blob - The file content as a Blob.
 * @return {Promise<string>} - Base64 encoded file data.
 */
const blobToBase64 = async blob => new Promise(resolve => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.readAsDataURL(blob);
});

/**
 * Set the browser tab title.
 *
 * @param {string} title - The title description to set.
 */
const setTabTitle = title => {
  document.title = title ? `${title} - Diligen` : 'Diligen';
};

export {
  blobToBase64,
  closest,
  downloadResponse,
  downloadResponseFrom,
  FILE_NAME_PREFIX,
  forceBrowserDownload,
  setTabTitle,
};
