import qs from 'qs';
import { isNumeric } from 'helpers/parse-utils/parse-utils';

const isBoolean = (str) => ['false', 'true'].includes(str);

/**
 * Parses a query string. Parses booleans, numbers and '' (empty strings treated as null)
 * @param  {string} query - what we can see in the browser url after "?"
 * @return {object} parsed query
 */
const qsParser = (query) =>
  qs.parse(query, {
    decoder(str, defaultDecoder) {
      const decoded = defaultDecoder(str);
      if (isNumeric(decoded)) {
        return parseFloat(decoded);
      }

      if (isBoolean(decoded)) {
        return decoded === 'true';
      }

      if (decoded === '') {
        return null;
      }

      return decoded;
    },
  });

export default qsParser;
