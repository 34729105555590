import Vue from 'vue';
import axios from 'helpers/diligen-xhr-axios';
import { downloadResponseFrom } from 'helpers/dom-utils';
import getErrorMessage from 'helpers/get-api-error-message';
import getSocket from 'helpers/socket-utils';
import { nameCompare } from 'helpers/sort-utils';
import { isPositive, isNegative } from 'shared/clause-determination-helper';
import { F1_HISTORY_LENGTH } from 'shared/constants/example-bot';
import {
  EXAMPLE_MIN_COUNT_BEFORE_GENERATION,
  SUGGESTION_MIN_COUNT_BEFORE_GENERATION,
} from 'shared/constants/example-suggestions';
import { A } from 'shared/constants/model-grades';

/**
 * Convert clause data to UI model format.
 *
 * @param {object} clause - The clause to map.
 */
const mapClauseToModel = clause => ({
  ...clause,
  color: clause.hex_color,
});

/**
 * Convert model data into DB clause format.
 *
 * @param {object} model - The model to map.
 */
const mapModelToClause = model => {
  const clause = { ...model, hex_color: model.color };
  delete clause.color;
  return clause;
};

export default {
  state: {
    clauses: [],
    clauseModels: [],
    clauseCategories: [],
    clauseExamples: [],
    clauseActive: true,
    completeToastDisplayed: false,
    documentsCount: null,
    grade: null,
    f1History: [],
    misses: [],
    missesDisplayed: false,
    sourceProjects: [],
    suggestions: [],
    suggestionsLoading: false,
    suggestionsErrorMessage: null,
    stopPolling: false,
    name: '',
    socket: null,
  },
  getters: {
    /**
     * Get only the positive examples from all clause examples.
     * @param {object} state - Vuex state to read from.
     * @param {Array<object>} state.clauseExamples - Clause examples from vuex state.
     *
     * @return {Array<object>} - Positive clause examples.
     */
    positiveExamples({ clauseExamples }) {
      return clauseExamples.filter(isPositive);
    },
    /**
     * Get only the negative examples from all clause examples.
     * @param {object} state - Vuex state to read from.
     * @param {Array<object>} state.clauseExamples - Clause examples from vuex state.
     *
     * @return {Array<object>} - Negative clause examples.
     */
    negativeExamples({ clauseExamples }) {
      return clauseExamples.filter(isNegative);
    },
    /**
     * Get only the false positive examples from the positive example set.
     * @param {object} state - Vuex state to read from.
     * @param {Array<number>} state.misses - Array of missed clause example ids.
     *
     * @return {Array<object>} - False positive clause examples.
     */
    falsePositiveExamples(state, { positiveExamples }) {
      return positiveExamples.filter(example => state.misses.includes(example.id));
    },
    /**
     * Get only the false negative examples from the negative example set.
     * @param {object} state - Vuex state to read from.
     * @param {Array<number>} state.misses - Array of missed clause example ids.
     *
     * @return {Array<object>} - False negative clause examples.
     */
    falseNegativeExamples(state, { negativeExamples }) {
      return negativeExamples.filter(example => state.misses.includes(example.id));
    },
    /**
     * Get the set of examples to be displayed in the UI.
     * @param {object} state - Vuex state to read from.
     * @param {object} getters - Vuex getters to read from.
     * @param {Array<object>} getters.positiveExamples - The positive examples.
     * @param {Array<object>} getters.negativeExamples - The negative examples.
     * @param {Array<object>} getters.falsePositiveExamples - The false positive examples.
     * @param {Array<object>} getters.falseNegativeExamples - The false negative examples.
     *
     * @return {Array<object>} - The examples to display.
     */
    displayedExamples(state, { positiveExamples, negativeExamples, falsePositiveExamples, falseNegativeExamples }) {
      return state.missesDisplayed
        ? [falsePositiveExamples, falseNegativeExamples]
        : [positiveExamples, negativeExamples];
    },
    /**
     * Get the negative example ratio of the model.
     * @param {object} state - Vuex state to read from.
     * @param {object} getters - Vuex getters to read from.
     * @param {Array<object>} getters.positiveExamples - The positive examples.
     * @param {Array<object>} getters.negativeExamples - The negative examples.
     *
     * @return {number} - Ratio of negative:positive examples.
     */
    negativeExampleRatio(state, { positiveExamples, negativeExamples }) {
      return negativeExamples.length / positiveExamples.length;
    },
    /**
     * Get the f1 score difference between the first and last history entry.
     * @param {object} state - Vuex state to read from.
     *
     * @return {number|null} - Score difference over F1_HISTORY_LENGTH iterations.
     */
    f1HistoryScoreChange(state) {
      return state.f1History.length === F1_HISTORY_LENGTH
        ? state.f1History[0] - state.f1History[F1_HISTORY_LENGTH - 1]
        : null;
    },
    /**
     * Get the clause category names.
     * @param {object} state - Vuex state to read from.
     *
     * @return{Array<string>}
     */
    categoryNames(state) {
      return state.clauseCategories.sort(nameCompare).map(({ name }) => name);
    },
    /**
     * Get only the user added clause categories.
     * @param {object} state - Vuex state to read from.
     *
     * @return {Array<object>} - User added categories.
     */
    userClauseCategories(state) {
      return state.clauseCategories.filter(({ added_by }) => added_by !== null);
    },
    hasSuggestions(state) {
      return state.suggestions.length > 0;
    },
    moreSuggestionsNeeded(state) {
      return (state.suggestions.length < SUGGESTION_MIN_COUNT_BEFORE_GENERATION) && !state.suggestionsLoading;
    },
    sourceProjectIds(state) {
      return state.sourceProjects.map(({ id }) => id);
    },
  },
  mutations: {
    /* eslint-disable no-param-reassign */
    /**
     * Adds a new category to the state.
     * @param {object} state - Vuex state.
     * @param {object} field - The new category to add.
     */
    addCategory(state, category) {
      state.clauseCategories.push(category);
    },
    /**
     * Updates a category.
     * @param {object} state - Vuex state.
     * @param {object} options
     * @param {number} options.id - The id of the category to update.
     * @param {object} options.name - The updated name.
     */
    updateCategory(state, { id, name }) {
      const updatedCategory = state.clauseCategories.find(category => category.id === id);
      Object.assign(updatedCategory, { name });

      state.clauseModels = state.clauseModels.map(model => (
        model.category_id === id
          ? { ...model, category: name }
          : model
      ));
    },
    addClauseExamples(state, examples) {
      state.clauseExamples.unshift(...examples);
    },
    addModel(state, model) {
      state.clauseModels.unshift(model);
    },
    deleteCategory(state, id) {
      const generalCategory = state.clauseCategories.find(category => category.name === 'General');

      state.clauseCategories = state.clauseCategories.filter(category => category.id !== id);
      state.clauseModels = state.clauseModels.map(model => (
        model.category_id === id
          ? { ...model, category_id: generalCategory.id, category: generalCategory.name }
          : model
      ));
    },
    /**
     * Delete example from vuex store.
     * @param {object} state - state of vuex training module.
     * @param {number} id - id of the clause example that has been removed.
     */
    deleteClauseExample(state, id) {
      state.clauseExamples = state.clauseExamples.filter(example => example.id !== id);
    },
    deleteModel(state, id) {
      state.clauseModels = state.clauseModels.filter(model => model.id !== id);
    },
    deleteSuggestion(state, id) {
      state.suggestions = state.suggestions.filter(suggestion => suggestion.id !== id);
    },
    updateClauseExample(state, patch) {
      const exampleIdx = state.clauseExamples.findIndex(({ id }) => id === patch.id);
      state.clauseExamples.splice(exampleIdx, 1, { ...state.clauseExamples[exampleIdx], ...patch });
    },
    updateModel(state, { id, patch }) {
      const patchCategory = state.clauseCategories.find(category => category.id === patch.category_id);
      state.clauseModels = state.clauseModels.map(model => (
        model.id !== id
          ? model
          : { ...model, ...patch, category: patchCategory.name }
      ));
    },
    setSuggestions(state, suggestions) {
      state.suggestions = suggestions;
    },
    setCategories(state, { categories }) {
      state.clauseCategories = categories;
    },
    setClauses(state, clauses) {
      state.clauses = clauses;
    },
    setClauseModels(state, { clauses }) {
      state.clauseModels = clauses.map(mapClauseToModel);
    },
    setDocumentsCount(state, count) {
      state.documentsCount = count;
    },
    setExamples(state, examples) {
      state.clauseExamples = examples;
    },
    setName(state, name) {
      state.name = name;
    },
    setLoading(state, status) {
      state.suggestionsLoading = status;
    },
    setSourceProjects(state, projects) {
      state.sourceProjects = projects;
    },
    setSuggestionsErrorMessage(state, toast) {
      state.suggestionsErrorMessage = toast;
    },
    setStopPolling(state, status) {
      state.stopPolling = status;
    },
    setGrade(state, grade) {
      state.grade = grade;
    },
    setMisses(state, misses) {
      state.misses = misses;
    },
    setClauseActive(state, isActive) {
      state.clauseActive = isActive;
    },
    setTrainingSocket(state, socket) {
      state.socket = socket;
    },
    setCompleteToastDisplayed(state, status) {
      state.completeToastDisplayed = status;
    },
    setMissesDisplayed(state, status) {
      state.missesDisplayed = status;
    },
    setF1History(state, f1History) {
      state.f1History = f1History;
    },
    setF1HistoryItem(state, f1Score) {
      if (state.f1History.length === F1_HISTORY_LENGTH) {
        state.f1History.shift();
      }
      state.f1History.push(f1Score);
    },
    /* eslint-enable no-param-reassign */
  },
  actions: {
    /**
     * Get suggestions for this clause.
     *
     * @param {Function} commit - Commit changes to the state.
     * @param {object} state - The state to read.
     * @param {object} rootState - The routing state.
     * @param {Function} dispatch - Dispatch actions
     */
    // eslint-disable-next-line complexity
    async getSuggestions({ commit, state, rootState, dispatch }) {
      try {
        if (state.stopPolling || !state.clauseActive) {
          // when stopPolling is true the user is navigating away from the training page
          // reset the polling state by setting stopPolling and suggestionsLoading to false
          // this prevents polling continuing on other pages and allows polling to start up again if they navigate back
          commit('setStopPolling', false);
          commit('setLoading', false);
          return;
        }
        commit('setLoading', true);
        const clauseId = rootState.route.params.clause_id;
        let query = {};
        if (state.sourceProjects.length) {
          query.sourceProjectIds = state.sourceProjects.map(({ id }) => id);
        }
        const response = await axios.get(`/api/suggestions/${clauseId}`, { params: query });

        // Suggestions are being generated, wait five seconds and try again
        if (response.status === 202 && state.clauseExamples.length >= EXAMPLE_MIN_COUNT_BEFORE_GENERATION) {
          setTimeout(() => dispatch('getSuggestions'), 5000);
        }
        else {
          if (response.status === 200) {
            commit('setSuggestions', response.data.data.suggestions);
          }
          commit('setSuggestionsErrorMessage', null);
          commit('setLoading', false);
        }
      }
      catch (error) {
        const message = getErrorMessage(error, 'Unable to fetch suggestions, please try again in a moment, or if you continue to get this message, email <a href="mailto:help@diligen.com">help@diligen.com</a> for support.'); // eslint-disable-line max-len
        commit('setSuggestionsErrorMessage', message);
      }
    },

    /**
     * Get clause details and clause examples for this clause.
     *
     * @param {Function} commit - Commit changes to the state.
     * @param {object} rootState - The routing state.
     */
    async initClauseExamples({ commit, rootState }) {
      const clauseId = rootState.route.params.clause_id;
      const [examplesResponse, clauseResponse] = await Promise.all([
        axios.get(`/api/clauses/${clauseId}/examples`),
        axios.get(`/api/clauses/${clauseId}`),
      ]);
      const { examples } = examplesResponse.data.data;
      const { clause: { name, grade, misses, active }, f1History } = clauseResponse.data.data;
      commit('setExamples', examples);
      commit('setName', name);
      commit('setGrade', grade);
      commit('setMisses', misses);
      commit('setClauseActive', active);
      commit('setF1History', f1History);
    },

    /**
     *  Initialize the training page websocket and save it to state.
     *
     * @param {object} context - Vuex action context.
     * @param {Function} context.commit - Function calling vuex state mutations.
     * @param {object} context.state - Vuex state to read.
     * @param {object} context.rootState - Vuex root state to read.
     */
    initTrainingSocket({ commit, state, rootState }) {
      const socket = getSocket();
      socket.on('modelUpdate', data => {
        if (data.clauseId === parseInt(rootState.route.params.clause_id, 10)) {
          // When the grade changes to A display the completion toast if it hasn't already been displayed.
          if (data.grade === A && !state.completeToastDisplayed) {
            commit('setCompleteToastDisplayed', true);
            Vue.diligenToast.showSuccess(
              `This model is perfoming well and is ready to be tested on a project.
              <span style="display: flex;"><a href="/projects">Go to projects</a></span>`,
              {
                sticky: true,
                action: [{
                  text: 'Close',
                  onClick: (e, toast) => toast.goAway(0),
                }],
              },
            );
          }
          commit('setGrade', data.grade);
          commit('setMisses', data.misses);
          commit('setClauseActive', data.active);
          commit('setF1HistoryItem', data.f1_score);

          if (state.missesDisplayed && !data.misses.length) {
            commit('setMissesDisplayed', false);
          }
        }
      });
      commit('setTrainingSocket', socket);
    },

    /**
     * Disconnect the training page websocket if one exists.
     *
     * @param {object} state - Vuex state to read.
     * @param {Socket} state.socket - Socket.io socket.
     */
    disconnectTrainingSocket({ state: { socket } }) {
      if (socket) {
        socket.disconnect();
      }
    },

    /**
     * Save a clause example to the DB & local state.
     *
     * @param {Function} commit - Commit changes to the state.
     * @param {object} example - The example to save.
     */
    async saveExample({ commit }, example) {
      if (example.id) {
        const { project_name, project_deleted, ...exampleData } = example;
        await axios.patch('/api/examples', exampleData);
        commit('deleteSuggestion', example.id);
        commit('addClauseExamples', [{ ...example, has_access: true }]);
      }
      else {
        const response = await axios.post('/api/examples', example);
        // One example for each ml_text provided - map the data back to the expected structure.
        const examples = response.data.data.examples.map(exampleData => ({ ...exampleData, has_access: true }));
        commit('addClauseExamples', examples);
      }
    },

    /**
     * Delete a suggestion from the DB & local state.
     *
     * @param {Function} commit - Commit changes to the state.
     * @param {object} suggestion - The suggestion to delete.
     */
    async deleteSuggestion({ commit }, suggestion) {
      await axios.delete(`/api/examples/${suggestion.id}`);
      commit('deleteSuggestion', suggestion.id);
    },

    /**
     * Delete an example from the DB.
     *
     * @param {object} context - Vuex action context.
     * @param {object} example - The example to delete.
     */
    async deleteClauseExample(context, example) {
      await axios.delete(`/api/examples/${example.id}`);
    },

    /**
     * Update an example in the DB & local state.
     *
     * @param {object} context - Vuex action context.
     * @param {Function} context.commit - Commit changes to the state.
     * @param {object} example - The example to update.
     */
    async updateExample({ commit }, example) {
      await axios.patch('/api/examples', example);
      commit('updateClauseExample', example);
    },

    /**
     * Generate suggestions from search keywords.
     *
     * @param {Function} commit - Commit changes to the state.
     * @param {object} rootState - The routing state.
     * @param {string} text - The keyword text to generate suggestions from.
     *
     * @return {number} The number of suggestions found.
     */
    async generateFromSearch({ commit, state, rootState, getters }, text) {
      const clauseId = rootState.route.params.clause_id;
      const data = { text };
      if (getters.sourceProjectIds.length) {
        data.sourceProjectIds = getters.sourceProjectIds;
      }
      const response = await axios.post(`/api/clauses/${clauseId}/generateFromSearch`, data);
      if (!response.data.data.suggestions.length) {
        const error = new Error('No suggestions available for the provided keyword.');
        error.status = 200;
        throw error;
      }
      if (state.suggestionsErrorMessage) {
        commit('setSuggestionsErrorMessage', null);
      }
      commit('setSuggestions', response.data.data.suggestions);
      return response.data.data.suggestions.length;
    },

    /**
     * Get the count of documents for the users projects.
     *
     * @param {Function} commit - Commit changes to the state.
     */
    async getDocumentsCount({ commit }) {
      const { data } = await axios.get('/api/users/me/documentCount');
      commit('setDocumentsCount', data.data.count);
    },

    /**
     *  Get a unique list of all clause categories.
     *
     * @param {Function} commit - Commit changes to the state.
     */
    async fetchCategories({ commit }) {
      const response = await axios.get('/api/clauses/categories');
      commit('setCategories', response.data.data);
    },

    /**
     * Gets and commits all system clauses.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     */
    async fetchClauses({ commit }) {
      const { data } = await axios.get('/api/clauses');
      commit('setClauses', data.data);
    },

    /**
     * Get user-trained clause models and example counts.
     *
     * @param {Function} commit - Commit changes to the state.
     */
    async fetchModels({ commit }) {
      const response = await axios.get('/api/clauseData');
      commit('setClauseModels', response.data.data);
    },

    /**
     * Create a new clause model for training.
     *
     * @param {Function} commit - Commit changes to the state.
     * @param {object} payload - The model to create.
     */
    async createModel({ commit }, payload) {
      const { data } = await axios.post('/api/clauses', mapModelToClause(payload));
      commit('addModel', { ...data.data, color: data.data.hex_color });
      return data.data.id;
    },

    /**
     * Update a clause model.
     *
     * @param {Function} commit - Commit changes to the state.
     * @param {number} id - The id of the clause to update.
     * @param {object} patch - The patch data to send.
     */
    async updateModel({ commit }, { id, patch }) {
      await axios.patch(`/api/clauses/${id}`, mapModelToClause(patch));
      commit('updateModel', { id, patch });
    },

    /**
     * Delete a clause model.
     *
     * @param {Function} commit - Commit changes to the state.
     * @param {number} id - The id of the clause to delete.
     */
    async deleteModel({ commit }, { id }) {
      await axios.delete(`/api/deleteModel/${id}`);
      commit('deleteModel', id);
    },

    /**
     * Add a new clause category.
     *
     * @param {Function} commit - Commit changes to the state.
     * @param {string} name - The name of the category to create.
     */
    async addCategory({ commit }, { name }) {
      const { data } = await axios.post('/api/clauses/categories', { name });
      commit('addCategory', data.data);
    },

    /**
     * Update a clause category.
     *
     * @param {Function} commit - Commit changes to the state.
     * @param {object} patch - The patch data to send.
     */
    async updateCategory({ commit }, { id, name }) {
      await axios.patch(`/api/clauses/categories/${id}`, { name });
      commit('updateCategory', { id, name });
    },

    /**
     * Delete a clause category.
     *
     * @param {Function} param0 - Commit changes to the state.
     * @param {number} param1 - The id of the category to delete.
     */
    async deleteCategory({ commit }, categoryId) {
      // deleteCategory mutation is called within the modal
      await axios.delete(`/api/clauses/categories/${categoryId}`);
    },

    /**
     * Download model data as a csv.
     */
    async downloadExamples(context, { id }) {
      const axiosPromise = axios.get(`/api/clauses/${id}/downloadData`, {
        responseType: 'blob',
      });
      await downloadResponseFrom(axiosPromise);
    },

    /** Remove any examples in the state that do not belong to the source project list. */
    pruneSuggestionsForSourceProjects({ commit, state, getters }) {
      if (getters.sourceProjectIds.length) {
        const prunedSuggestions = state.suggestions.filter(
          ({ project_id }) => getters.sourceProjectIds.includes(project_id),
        );
        commit('setSuggestions', prunedSuggestions);
      }
    },
  },
};
