<template>
  <button :class="[`btn-${variant}`, 'btn-add']" :disabled="disabled" @click="onClick">
    <PlusSvg class="plus-icon" />
    <span class="button-text">{{ text }}</span>
  </button>
</template>

<script>
import PlusSvg from 'svg/Plus.vue';

export default {
  components: {
    PlusSvg,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    variant: {
      type: String,
      default: 'primary',
      validator: variant => ['primary', 'secondary'].includes(variant),
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add {
  display: inline-flex;
  align-items: center;
  min-width: 38px;

  @include sm {
    padding-right: 24px;
    padding-left: 14px;
  }

  .plus-icon {
    margin-right: 0;
    width: 20px;
    height: 20px;

    @include sm {
      margin-right: 5px;
    }
  }

  .button-text {
    display: none;

    @include sm {
      display: block;
    }
  }
}
</style>
