<template>
  <div class="primary-header">
    <h1 class="heading-1">
      <slot />
    </h1>
    <div v-if="hasDescriptionSlot" class="description default-text">
      <slot name="description" />
    </div>
    <div class="buttons">
      <slot name="secondary-button" />
      <PrimaryAddButton v-if="hasAddButton" v-bind="addButtonProps" />
    </div>
  </div>
</template>

<script>
import PrimaryAddButton from 'components/route-level/PrimaryAddButton.vue';

export default {
  components: {
    PrimaryAddButton,
  },
  props: {
    addButtonProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasDescriptionSlot() {
      return this.$slots.description;
    },
    hasAddButton() {
      return Object.keys(this.addButtonProps).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.primary-header {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 33px;
  border-bottom: 1px solid $color-divider;
  width: 100%;
}

.description {
  margin-top: 10px;
  color: $color-primary-header-description;
}

.buttons {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
