<template>
  <!-- eslint-disable max-len, vue/html-indent, vue/html-closing-bracket-spacing -->
  <svg class="svg-component" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12.4 11.997L7.306 6.792a1.063 1.063 0 0 1 0-1.483 1.004 1.004 0 0 1 1.449 0l5.818 5.947c.193.197.302.461.302.741 0 .28-.109.544-.298.742L8.75 18.695a1.011 1.011 0 0 1-1.449 0 1.063 1.063 0 0 1 0-1.483l5.1-5.215z"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightSvg',
};
</script>
