import axios from 'axios';
import Vue from 'vue';
import uuid from './client-uuid';
import jwtUtils from './jwt-utils';
import { TOKEN_EXPIRED } from 'shared/constants/error-codes';

// Toast should be shown only once per "page refresh".
let newVersionToastShown = false;

/**
 * Handle the request error based on the status.
 *
 * @param {object} error - The error object.
 * @returns {boolean} - true if error was handled.
 */
// eslint-disable-next-line complexity
const handleErrorStatus = error => {
  const { response } = error;
  const status = response.status;

  if (status === 401) {
    const currentRelativeLocation = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    if (window.location.pathname === '/login') {
      return false; // Do nothing if already on the login page
    }
    const errorCode = response.data?.errors?.[0]?.code;
    if (errorCode === TOKEN_EXPIRED || errorCode === 'token_invalid') {
      return false;
    }
    // Redirect to the login page with the current relative location for possible redirection back post-login
    window.location.replace(`/login?redirect_to=${encodeURIComponent(currentRelativeLocation)}`);
  }
  else if (status === 402 && response.data && response.data.location) {
    window.location.replace(response.data.location);
  }
  else if (status === 403) {
    Vue.diligenToast.showApiError(
      error,
      'You do not have access to this resource. Contact an Account Owner to request access.',
      { isSingleton: true },
    );
  }
  else if (status === 503) {
    Vue.diligenToast.showApiError(
      error,
      'Diligen is currently undergoing maintenance. Apologies for the inconvenience.',
      { isSingleton: true },
    );
  }
  else {
    return false;
  }
  return true;
};

// Create an Axios instance
const axiosInstance = axios.create({
  paramsSerializer: params => {
    /* eslint-disable complexity */
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      const value = params[key];
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        Object.keys(value).forEach(subKey => {
          const subValue = value[subKey];
          // Only include subValue if it's not undefined, not null, and not an empty string
          if (subValue !== undefined && subValue !== null && subValue !== '' && (
            (Array.isArray(subValue) && subValue.length > 0)
            || (!Array.isArray(subValue) && typeof subValue !== 'object')
          )) {
            if (key === 'filter' && Array.isArray(subValue)) {
              // Special handling for filter array values
              subValue.forEach(item => searchParams.append(`${key}[${subKey}]`, item));
            }
            else {
              searchParams.append(`${key}[${subKey}]`, subValue);
            }
          }
        });
      }
      else if (value !== undefined && value !== null && value !== '' && (
        !Array.isArray(value) || (Array.isArray(value) && value.length > 0)
      )) {
        searchParams.append(key, value);
      }
    });
    return searchParams.toString();
  },
});

// Interceptor to modify request before it is sent
axiosInstance.interceptors.request.use(
  config => {
    const modifiedConfig = { ...config };
    // Prefix any absolute URLs
    if (modifiedConfig.url[0] === '/') {
      modifiedConfig.url = `${window.location.protocol}//${window.location.host}${config.url}`;
    }

    // Set JWT in headers if available
    const jwt = jwtUtils.getFromStorage();
    if (jwt) {
      modifiedConfig.headers.Authorization = `Bearer ${jwt}`;
    }

    // Sets same uuid for each request. The uuid varies per tab in the web browser even for the same jwt session.
    modifiedConfig.headers.uuid = uuid;

    return modifiedConfig;
  },
  error => Promise.reject(error),
);

// Interceptor to handle responses
axiosInstance.interceptors.response.use(
  response => {
    const releaseId = response.headers['application-release-id'];
    if (!newVersionToastShown && releaseId && releaseId !== process.env.VUE_APP_RELEASE) {
      const message = 'A new version of Diligen has been released. Please save your work, then reload the page to get the latest version.'; // eslint-disable-line max-len
      const options = process.env.VUE_APP_DILIGEN_ENV === 'development' ? { delay: 1000 } : { sticky: true };
      Vue.diligenToast.showInfo(message, { ...options, isSingleton: true });
      newVersionToastShown = true;
    }
    return response;
  },
  error => {
    const clonedError = { ...error };
    const handled = handleErrorStatus(clonedError);
    clonedError.handledByDiligenXhr = handled;

    if (handled) {
      return Promise.reject(error); // Axios error object is available upstream
    }

    // Reject the promise to continue the error propagation
    return Promise.reject(clonedError);
  },
);

export default axiosInstance;
