const CLAUSE_IDENTIFIER = 'ml_key';
const PROJECT_FIELD_IDENTIFIER = 'id';

/**
 * Check if passed field is project field (not a clause field)
 * @param  {object} field - clause or project field
 * @return {boolean} - true if project field passed in
 */
const isProjectField = (field) => !field[CLAUSE_IDENTIFIER];

/**
 * Get id of a given field (project field or a clause)
 * @param  {object} field - clause or project field
 * @return {string|number} field identifier (mlKey for clause and id for project field)
 */
const getFieldId = (field) => (isProjectField(field) ? field[PROJECT_FIELD_IDENTIFIER] : field[CLAUSE_IDENTIFIER]);

/**
 * Gets clause from provided clauses
 * @param {object} options
 * @param {Array<object>} options.clauses - array of clauses to scan
 * @param {string | number} options.mlKey - mlKey clause identifier
 * @return {object|undefined} clause if found, otherwise undefined
 */
const getClause = ({ clauses, mlKey }) =>
  clauses.find((clause) => clause[CLAUSE_IDENTIFIER] === mlKey);

/**
 * Gets project field from provided project fields
 * @param {object} options
 * @param {Array<object>} options.projectFields - array of project fields to scan
 * @param {string | number} options.id - project field identifier
 * @return {object|undefined} project field if found, otherwise undefined
 */
const getProjectField = ({ projectFields, id }) =>
  projectFields.find((projectField) => projectField[PROJECT_FIELD_IDENTIFIER] === id);

/**
 * Gets field (clause or project field) from a given preference
 * @param  {string|number} preference - either mlKey identifing a clause or project field id
 * @param  {Array<object>} clauses - array of clauses among which we look for the preferenced field
 * @param  {Array<object>} projectFields - array of project fields among which we look for the preferenced field
 * @return {object|undefined} - clause, project field or undefined if preferenced field not found
 */
const getField = (preference, clauses, projectFields) =>
  getClause({ clauses, mlKey: preference }) || getProjectField({ projectFields, id: parseInt(preference, 10) });

export {
  isProjectField,
  getClause,
  getProjectField,
  getFieldId,
  getField,
};
