import { get, omit } from 'lodash';

/**
 * Higher order function which wraps a vuex action to add processing indication while the action is being resolved.
 * The wrapped vuex action is called with the same payload as the wrapping function unless we pass in a payload object
 * with supressProcessingIndicator member in the wrapping action. In that case supressProcessingIndicator member
 * is omitted in the payload passed into the wrapped vuex action.
 * @param  {string} actionName - whatever should we put into the array indicating in-progress actions.
 * @param  {Function} action - async function
 * that will be marked as processing right before it's called and unmarked when done.
 * this function takes arguments as any vuex action does.
 * @return {(context, payload) => void | Promise<void>} vuex action.
 */
const withProcessingIndicator = (actionName, action) =>
  /**
   * @param {object} context - vuex action context
   * @param {Mixed} payload - vuex action payload, with an optional payload.supressProcessingIndicator
   * which controls if we want to supress adding the processing indicator for the single invocation.
   */
  (context, payload) => {
    const supressProcessingIndicator = get(payload, 'supressProcessingIndicator');
    const actionPayload = typeof supressProcessingIndicator === 'boolean'
      ? omit(payload, 'supressProcessingIndicator')
      : payload;

    const promise = action(context, actionPayload);
    return supressProcessingIndicator
      ? promise
      : context.dispatch('addProcessingIndicator', { actionName, promise });
  };

const actionsIndicator = {
  state: {
    actionsInProgress: [],
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    /**
     marks a named action as started.
     */
    actionStart(state, actionName) {
      state.actionsInProgress.push(actionName);
    },
    /**
     * Marks action as ended
     */
    actionEnd(state, actionName) {
      const actionIndex = state.actionsInProgress.indexOf(actionName);
      if (actionIndex !== -1) {
        state.actionsInProgress.splice(actionIndex, 1);
      }
    },
  },
  /* eslint-enable no-param-reassign */
  actions: {
    /**
     * Adds processing indicator for a given promise using the actionName.
     * Progress indication is removed when promise resolves.
     */
    async addProcessingIndicator({ commit }, { actionName, promise }) {
      commit('actionStart', actionName);
      try {
        await promise;
      }
      finally {
        commit('actionEnd', actionName);
      }
      return promise;
    },
  },
};

export {
  actionsIndicator,
  withProcessingIndicator,
};
