import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import { sync } from 'vuex-router-sync';

import App from './App.vue';
import executeGlobalRegistrations from './global-registrations';
import router from './router';
import store from './store/store';
import { ERROR_401, ERROR_402 } from '@/constants/errors';

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    release: process.env.VUE_APP_RELEASE,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    Vue,
    // preserves calling Vue logError in dev. Otherwise what goes to sentry is not shown in console.
    logErrors: process.env.VUE_APP_DILIGEN_ENV === 'development',
    ignoreErrors: [
      ERROR_401,
      ERROR_402,
      'Request has been terminated', // Ignores error when your jwt expires on FF.
    ],
  });
}

Vue.config.productionTip = false;
// Provide custom warnHandler to suppress noisy, harmless ".native" warning.
// You can see more about it in the CollapsableMenuItem.vue component.
Vue.config.warnHandler = (msg, vm, trace) => {
  if (msg.startsWith('The .native modifier for v-on is only valid on components')) {
    return false;
  }
  console.error((`[Vue (Diligen) warn]: ${msg}${trace}`));
};

executeGlobalRegistrations(Vue);

sync(store, router);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
