/**
 * If the user is pending (active, but ToU not accepted).
 *
 * @param  {object} user - The user to check.
 *
 * @return {boolean}
 */
function isPending(user) {
  return !user.is_deactivated && !user.tou_accepted;
}

/**
 * If the user is active (active, and ToU accepted).
 *
 * @param  {object} user - The user to check.
 *
 * @return {boolean}
 */
function isActive(user) {
  if (!user) {
    return false;
  }
  return !user.is_deactivated && user.tou_accepted;
}

/**
 * If the user is inactive.
 *
 * @param  {object} user - The user to check.
 *
 * @return {boolean}
 */
function isInactive(user) {
  return user.is_deactivated;
}

/**
 * Compare values in ascending direction, case insensitive.
 *
 * @param  {object} a - The first compared element.
 * @param  {object} b - The second compared element.
 *
 * @return {number}
 */
function compareAscendingInsensitive(a, b) {
  return a.first_name.localeCompare(b.first_name);
}

export {
  isPending,
  isActive,
  isInactive,
  compareAscendingInsensitive,
};
