<template>
  <!-- eslint-disable max-len, vue/html-indent, vue/html-closing-bracket-spacing -->
  <svg class="svg-component" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
    <path id="a" d="M11.99 10.577l5.281-5.28a1.013 1.013 0 0 1 1.432 1.432l-5.28 5.28 5.284 5.285a.999.999 0 1 1-1.414 1.413l-5.284-5.284-5.28 5.28a1.013 1.013 0 0 1-1.432-1.432l5.28-5.28-5.284-5.285a.999.999 0 1 1 1.414-1.413l5.284 5.284z"/>
  </svg>
</template>

<script>
export default {
  name: 'CloseSvg',
};
</script>
