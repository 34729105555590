/* global Stripe */

import request from 'helpers/diligen-xhr';

const CANADA = 'canada';

const taxRates = {
  ontario: 13,
  ont: 13,
  on: 13,
  default: 5,
};

export default {
  state: {
    taxRate: 0,
    coupon: false,
    cardInfo: {},
    planChangesPreview: [],

    companyName: '',
    email: '',

    line1: '',
    line2: '',
    city: '',
    provinceState: '',
    country: '',
    zipPostal: '',

    stripe: {},
    stripeCard: {},
    stripeCardName: '',
    stripeCardNumberError: '',
    stripeCardExpiryError: '',
    stripeCardCodeError: '',
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    adjustTaxRate(state) {
      if (state.country.toLowerCase().trim() === CANADA) {
        state.taxRate = taxRates[state.provinceState.toLowerCase().trim()] || taxRates.default;
      }
    },

    setCoupon(state, coupon) {
      state.coupon = coupon;
    },

    setCardInfo(state, data) {
      state.cardInfo = data;
    },

    setPlanChangesPreview(state, data) {
      state.planChangesPreview = data;
    },

    setCompanyName(state, companyName) {
      state.companyName = companyName;
    },

    setEmail(state, email) {
      state.email = email;
    },

    setLine1(state, line1) {
      state.line1 = line1;
    },

    setLine2(state, line2) {
      state.line2 = line2;
    },

    setCity(state, city) {
      state.city = city;
    },

    setProvinceState(state, provinceState) {
      state.provinceState = provinceState;
    },

    setCountry(state, country) {
      state.country = country;
    },

    setZipPostal(state, zipPostal) {
      state.zipPostal = zipPostal;
    },

    setStripe(state, stripe) {
      state.stripe = stripe;
    },

    setStripeCard(state, stripeCard) {
      state.stripeCard = stripeCard;
    },

    setStripeCardName(state, stripeCardName) {
      state.stripeCardName = stripeCardName;
    },

    setStripeCardNumberError(state, stripeCardNumberError) {
      state.stripeCardNumberError = stripeCardNumberError;
    },

    setStripeCardExpiryError(state, stripeCardExpiryError) {
      state.stripeCardExpiryError = stripeCardExpiryError;
    },

    setStripeCardCodeError(state, stripeCardCodeError) {
      state.stripeCardCodeError = stripeCardCodeError;
    },
  },
  /* eslint-enable */
  actions: {
    /**
     * Load and save existing subscription card info in store.
     *
     * @param {import('vuex').ActionContext} commit - Function to commit changes via mutators.
     */
    initCardInfo: async ({ commit }) => {
      const data = (await request.get('/api/cardinfo')).body.data;
      commit('setCardInfo', data);
    },

    /**
     * Preview the changes to a subscription caused by a plan change.
     *
     * @param {import('vuex').ActionContext} commit - Function to commit changes via mutators.
     * @param {string} plan - The plan to preview changes for
     */
    fetchPreviewPlanChanges: async ({ commit }, plan) => {
      const data = (await request.get(`/api/preview/${plan}`)).body.data;
      commit('setPlanChangesPreview', data);
    },

    /**
     * Clears the currently-set coupon.
     *
     * @param {import('vuex').ActionContext} commit - Function to commit changes via mutators.
     */
    clearCoupon: ({ commit }) => {
      commit('setCoupon', false);
    },

    /**
     * Fetch info about a coupon from Stripe.
     *
     * @param {import('vuex').ActionContext} commit - Function to commit changes via mutators.
     * @param {string} code - The coupon code to check.
     */
    fetchCouponFromStripe: async ({ commit }, code) => {
      const data = (await request.get(`/api/coupons/${code}`)).body.data;
      commit('setCoupon', data.coupon);
    },

    loadStripe: async ({ dispatch }) => {
      const stripeSrc = 'https://js.stripe.com/v3/';
      const el = document.querySelector(`script[src="${stripeSrc}"]`);
      if (!el) {
        // Add script tag only if it has not yet been loaded.
        const stripeScript = document.createElement('script');
        stripeScript.setAttribute('src', stripeSrc);
        document.head.appendChild(stripeScript);
        stripeScript.onload = () => {
          // Initialize stripe once the script has loaded.
          dispatch('initStripe');
        };
      }
    },

    /**
     * Initialize the connection between Stripe and the form.
     *
     * Requires form elements mounted on page with IDs of:
     * - #stripe-card-number
     * - #stripe-card-expiry
     * - #stripe-card-cvc
     *
     * @param {import('vuex').ActionContext} commit - Function to commit changes via mutators.
     */
    initStripe: ({ commit }) => {
      const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      const elements = stripe.elements();

      const options = {
        style: {
          base: {
            fontSize: '16px',
            color: '#6D7A94',
            '::placeholder': {
              color: '#B5BCC9',
            },
          },
          invalid: {
            color: '#FF4141',
          },
        },
      };

      const stripeCard = elements.create('cardNumber', options);
      stripeCard.mount('#stripe-card-number');
      stripeCard.addEventListener('change', (event) => {
        commit('setStripeCardNumberError', event.error ? event.error.message : '');
      });

      const cardExpiry = elements.create('cardExpiry', options);
      cardExpiry.mount('#stripe-card-expiry');
      cardExpiry.addEventListener('change', (event) => {
        commit('setStripeCardExpiryError', event.error ? event.error.message : '');
      });

      const cardCvc = elements.create('cardCvc', options);
      cardCvc.mount('#stripe-card-cvc');
      cardCvc.addEventListener('change', (event) => {
        commit('setStripeCardCodeError', event.error ? event.error.message : '');
      });

      commit('setStripe', stripe);
      commit('setStripeCard', stripeCard);
    },

    setCompanyName({ commit }, companyName) {
      commit('setCompanyName', companyName);
    },

    setEmail({ commit }, email) {
      commit('setEmail', email);
    },

    setLine1({ commit }, line1) {
      commit('setLine1', line1);
    },

    setLine2({ commit }, line2) {
      commit('setLine2', line2);
    },

    setCity({ commit }, city) {
      commit('setCity', city);
    },

    setProvinceState({ commit, state }, provinceState) {
      commit('setProvinceState', provinceState);
      commit('adjustTaxRate');
    },

    setCountry({ commit, state }, country) {
      commit('setCountry', country);
      commit('adjustTaxRate');
    },

    setZipPostal({ commit }, zipPostal) {
      commit('setZipPostal', zipPostal);
    },

    setStripe({ commit }, stripe) {
      commit('setStripe', stripe);
    },

    setStripeCard({ commit }, stripeCard) {
      commit('setStripeCard', stripeCard);
    },

    setStripeCardName({ commit }, stripeCardName) {
      commit('setStripeCardName', stripeCardName);
    },

    setStripeCardNumberError({ commit }, stripeCardNumberError) {
      commit('setStripeCardNumberError', stripeCardNumberError);
    },

    setStripeCardExpiryError({ commit }, stripeCardExpiryError) {
      commit('setStripeCardExpiryError', stripeCardExpiryError);
    },

    setStripeCardCodeError({ commit }, stripeCardCodeError) {
      commit('setStripeCardCodeError', stripeCardCodeError);
    },
  },
};
